/* eslint-disable */
import { util, configure, Writer, Reader } from 'protobufjs/minimal';
import * as Long from 'long';

export const protobufPackage = '';

/**
 * This protocol buffer file creates objects that maintain the model structure
 * of a DataFeed configuration. These objects are used to retrieve and update
 * information in a data store that is needed for Statistics(Stats)
 * and HyperLogLog(HLL) generation.
 */

/**
 * The entity the DataFeed
 * is associated with
 * ex Patient, or Provider
 */
export enum Entity {
  PATIENT = 0,
  PROVIDER = 1,
  UNRECOGNIZED = -1,
}

export function entityFromJSON(object: any): Entity {
  switch (object) {
    case 0:
    case 'PATIENT':
      return Entity.PATIENT;
    case 1:
    case 'PROVIDER':
      return Entity.PROVIDER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Entity.UNRECOGNIZED;
  }
}

export function entityToJSON(object: Entity): string {
  switch (object) {
    case Entity.PATIENT:
      return 'PATIENT';
    case Entity.PROVIDER:
      return 'PROVIDER';
    default:
      return 'UNKNOWN';
  }
}

export enum FileFormat {
  DSV_QUOTE = 0,
  DSV_NOQUOTE = 1,
  ORC = 2,
  PARQUET = 3,
  JSON = 4,
  UNRECOGNIZED = -1,
}

export function fileFormatFromJSON(object: any): FileFormat {
  switch (object) {
    case 0:
    case 'DSV_QUOTE':
      return FileFormat.DSV_QUOTE;
    case 1:
    case 'DSV_NOQUOTE':
      return FileFormat.DSV_NOQUOTE;
    case 2:
    case 'ORC':
      return FileFormat.ORC;
    case 3:
    case 'PARQUET':
      return FileFormat.PARQUET;
    case 4:
    case 'JSON':
      return FileFormat.JSON;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FileFormat.UNRECOGNIZED;
  }
}

export function fileFormatToJSON(object: FileFormat): string {
  switch (object) {
    case FileFormat.DSV_QUOTE:
      return 'DSV_QUOTE';
    case FileFormat.DSV_NOQUOTE:
      return 'DSV_NOQUOTE';
    case FileFormat.ORC:
      return 'ORC';
    case FileFormat.PARQUET:
      return 'PARQUET';
    case FileFormat.JSON:
      return 'JSON';
    default:
      return 'UNKNOWN';
  }
}

/**
 * The attributes of the
 * given HLL config
 */
export enum Attribute {
  AGE = 0,
  DIAGNOSIS = 1,
  DRUG = 2,
  GENDER = 3,
  GENERIC_MEDICATION = 4,
  STATE = 5,
  REGION = 6,
  PROCEDURE = 7,
  BIOMARKER = 8,
  BMI = 9,
  LAB = 10,
  PROVIDER_SPECIALTY = 11,
  PROVIDER_ZIP = 12,
  PROVIDER_STATE = 13,
  PROVIDER_REGION = 14,
  TOTAL = 15,
  UNRECOGNIZED = -1,
}

export function attributeFromJSON(object: any): Attribute {
  switch (object) {
    case 0:
    case 'AGE':
      return Attribute.AGE;
    case 1:
    case 'DIAGNOSIS':
      return Attribute.DIAGNOSIS;
    case 2:
    case 'DRUG':
      return Attribute.DRUG;
    case 3:
    case 'GENDER':
      return Attribute.GENDER;
    case 4:
    case 'GENERIC_MEDICATION':
      return Attribute.GENERIC_MEDICATION;
    case 5:
    case 'STATE':
      return Attribute.STATE;
    case 6:
    case 'REGION':
      return Attribute.REGION;
    case 7:
    case 'PROCEDURE':
      return Attribute.PROCEDURE;
    case 8:
    case 'BIOMARKER':
      return Attribute.BIOMARKER;
    case 9:
    case 'BMI':
      return Attribute.BMI;
    case 10:
    case 'LAB':
      return Attribute.LAB;
    case 11:
    case 'PROVIDER_SPECIALTY':
      return Attribute.PROVIDER_SPECIALTY;
    case 12:
    case 'PROVIDER_ZIP':
      return Attribute.PROVIDER_ZIP;
    case 13:
    case 'PROVIDER_STATE':
      return Attribute.PROVIDER_STATE;
    case 14:
    case 'PROVIDER_REGION':
      return Attribute.PROVIDER_REGION;
    case 15:
    case 'TOTAL':
      return Attribute.TOTAL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Attribute.UNRECOGNIZED;
  }
}

export function attributeToJSON(object: Attribute): string {
  switch (object) {
    case Attribute.AGE:
      return 'AGE';
    case Attribute.DIAGNOSIS:
      return 'DIAGNOSIS';
    case Attribute.DRUG:
      return 'DRUG';
    case Attribute.GENDER:
      return 'GENDER';
    case Attribute.GENERIC_MEDICATION:
      return 'GENERIC_MEDICATION';
    case Attribute.STATE:
      return 'STATE';
    case Attribute.REGION:
      return 'REGION';
    case Attribute.PROCEDURE:
      return 'PROCEDURE';
    case Attribute.BIOMARKER:
      return 'BIOMARKER';
    case Attribute.BMI:
      return 'BMI';
    case Attribute.LAB:
      return 'LAB';
    case Attribute.PROVIDER_SPECIALTY:
      return 'PROVIDER_SPECIALTY';
    case Attribute.PROVIDER_ZIP:
      return 'PROVIDER_ZIP';
    case Attribute.PROVIDER_STATE:
      return 'PROVIDER_STATE';
    case Attribute.PROVIDER_REGION:
      return 'PROVIDER_REGION';
    case Attribute.TOTAL:
      return 'TOTAL';
    default:
      return 'UNKNOWN';
  }
}

export enum Interval {
  DAYS = 0,
  MONTHS = 1,
  YEARS = 2,
  UNRECOGNIZED = -1,
}

export function intervalFromJSON(object: any): Interval {
  switch (object) {
    case 0:
    case 'DAYS':
      return Interval.DAYS;
    case 1:
    case 'MONTHS':
      return Interval.MONTHS;
    case 2:
    case 'YEARS':
      return Interval.YEARS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Interval.UNRECOGNIZED;
  }
}

export function intervalToJSON(object: Interval): string {
  switch (object) {
    case Interval.DAYS:
      return 'DAYS';
    case Interval.MONTHS:
      return 'MONTHS';
    case Interval.YEARS:
      return 'YEARS';
    default:
      return 'UNKNOWN';
  }
}

/**
 * Config for the Data Source
 * of the DataModel
 */
export interface FileSource {
  path: string;
  delimiter: string;
  fileFormat: FileFormat;
}

/**
 * Config for Hive Data Source
 * of the DataModel
 */
export interface HiveSource {
  schema: string;
  tableName: string;
  partitionCondition: { [key: string]: string };
}

export interface HiveSource_PartitionConditionEntry {
  key: string;
  value: string;
}

/**
 * Mapping for field names customizable for a DataSet: Catalog how field names used in
 * an external data set correspond to those used in data attribute statement generation.
 * Note: date_field is repeated because it stores one or more fields names that should be
 * used to determine the canonical "date" of a given row of data
 */
export interface FieldMapping {
  patientIdentifierField: string;
  providerIdentifierField: string;
  dateFields: string[];
  dateWindowStartField: string;
  dateWindowEndField: string;
  ageField: string;
  yearOfBirthField: string;
  genderField: string;
  bmiField: string;
  bmiMeasurementField: string;
  patientStateField: string;
  patientZip3Field: string;
  providerStateField: string;
  providerZip3Field: string;
  providerSpecialtyField: string;
  procedureField: string;
  labField: string;
  drugField: string;
  diagnosisField: string;
  diagnosisQualField: string;
  genericMedicationField: string;
  datafeedIdField: string;
}

/**
 * Defines the
 * scala model in a DataFeed
 */
export interface DataModel {
  /** The ID of the data type for this model */
  dataType: string;
  fileSource: FileSource | undefined;
  hiveSource: HiveSource | undefined;
  statsConfig: DataModel_StatsConfig | undefined;
  hllConfig: DataModel_HLLConfig | undefined;
  /** Map of how field names used in an external datafeed map to those used in CustomModel */
  fieldMapping: FieldMapping | undefined;
}

/**
 * Config for generating statistics
 * information for the DataModel
 */
export interface DataModel_StatsConfig {
  /** Boolean configurations on what to calculate */
  topValues: boolean;
  fillRate: boolean;
  longitudinality: boolean;
  yearOverYear: boolean;
  keyStats: boolean;
  /**
   * The field that identifies rows that
   * belong to the same record
   */
  recordField: string;
}

/**
 * Config for generating HyperLogLog
 * information for the DataModel
 */
export interface DataModel_HLLConfig {
  hllModelName: string;
}

/**
 * Class for the DataFeed model.
 * It is used for retrieving and updating
 * information for Stats and HLL generation
 */
export interface DataFeedConfig {
  /** The unique ID for this data feed */
  id: string;
  /** A human-readable name for the data feed */
  name: string;
  /** All data models associated with this feed */
  models: DataModel[];
  /** All relevant entities for this feed */
  entities: Entity[];
  /**
   * Whether to ignore any provided start date when distilling facts
   * about this feed
   */
  ignoreStartDate: boolean;
  /** Whether to ignore any provided end date when processing this feed */
  ignoreEndDate: boolean;
  /**
   * Whether to include rows with null (or empty) dates when processing
   * this feed
   */
  includeNullDates: boolean;
  /** The earliest relevant date for this data feed */
  earliestDate: string;
  /**
   * The amount of time that this data lags before the end date of the
   * generation version's window.
   */
  dataLag: DataFeedConfig_DataLag | undefined;
  refreshConfig: DataFeedConfig_RefreshConfig | undefined;
  hllConfig: DataFeedConfig_HLLConfig | undefined;
}

/** A message for data lag */
export interface DataFeedConfig_DataLag {
  amount: number;
  interval: Interval;
}

/** The configuration defining refresh automation */
export interface DataFeedConfig_RefreshConfig {
  /** the rate at which a feed is refreshed. Monthly, quarterly, or yearly */
  rate: string;
  /** datetime of the first refresh run, rounded to the nearest 5 minutes */
  startDate: string;
  /** datetime of the final refresh run (optional) */
  endDate: string;
}

/**
 * The HLL Configuration for
 * the DataFeed.
 */
export interface DataFeedConfig_HLLConfig {
  partitions: number;
  attributes: Attribute[];
  privacyFiltering: boolean;
}

const baseFileSource: object = { path: '', delimiter: '', fileFormat: 0 };

export const FileSource = {
  encode(message: FileSource, writer: Writer = Writer.create()): Writer {
    if (message.path !== '') {
      writer.uint32(10).string(message.path);
    }
    if (message.delimiter !== '') {
      writer.uint32(18).string(message.delimiter);
    }
    if (message.fileFormat !== 0) {
      writer.uint32(24).int32(message.fileFormat);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): FileSource {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFileSource } as FileSource;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.path = reader.string();
          break;
        case 2:
          message.delimiter = reader.string();
          break;
        case 3:
          message.fileFormat = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FileSource {
    const message = { ...baseFileSource } as FileSource;
    if (object.path !== undefined && object.path !== null) {
      message.path = String(object.path);
    } else {
      message.path = '';
    }
    if (object.delimiter !== undefined && object.delimiter !== null) {
      message.delimiter = String(object.delimiter);
    } else {
      message.delimiter = '';
    }
    if (object.fileFormat !== undefined && object.fileFormat !== null) {
      message.fileFormat = fileFormatFromJSON(object.fileFormat);
    } else {
      message.fileFormat = 0;
    }
    return message;
  },

  toJSON(message: FileSource): unknown {
    const obj: any = {};
    message.path !== undefined && (obj.path = message.path);
    message.delimiter !== undefined && (obj.delimiter = message.delimiter);
    message.fileFormat !== undefined && (obj.fileFormat = fileFormatToJSON(message.fileFormat));
    return obj;
  },

  fromPartial(object: DeepPartial<FileSource>): FileSource {
    const message = { ...baseFileSource } as FileSource;
    if (object.path !== undefined && object.path !== null) {
      message.path = object.path;
    } else {
      message.path = '';
    }
    if (object.delimiter !== undefined && object.delimiter !== null) {
      message.delimiter = object.delimiter;
    } else {
      message.delimiter = '';
    }
    if (object.fileFormat !== undefined && object.fileFormat !== null) {
      message.fileFormat = object.fileFormat;
    } else {
      message.fileFormat = 0;
    }
    return message;
  },
};

const baseHiveSource: object = { schema: '', tableName: '' };

export const HiveSource = {
  encode(message: HiveSource, writer: Writer = Writer.create()): Writer {
    if (message.schema !== '') {
      writer.uint32(10).string(message.schema);
    }
    if (message.tableName !== '') {
      writer.uint32(18).string(message.tableName);
    }
    Object.entries(message.partitionCondition).forEach(([key, value]) => {
      HiveSource_PartitionConditionEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork()
      ).ldelim();
    });
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): HiveSource {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseHiveSource } as HiveSource;
    message.partitionCondition = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.schema = reader.string();
          break;
        case 2:
          message.tableName = reader.string();
          break;
        case 3:
          const entry3 = HiveSource_PartitionConditionEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.partitionCondition[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HiveSource {
    const message = { ...baseHiveSource } as HiveSource;
    message.partitionCondition = {};
    if (object.schema !== undefined && object.schema !== null) {
      message.schema = String(object.schema);
    } else {
      message.schema = '';
    }
    if (object.tableName !== undefined && object.tableName !== null) {
      message.tableName = String(object.tableName);
    } else {
      message.tableName = '';
    }
    if (object.partitionCondition !== undefined && object.partitionCondition !== null) {
      Object.entries(object.partitionCondition).forEach(([key, value]) => {
        message.partitionCondition[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: HiveSource): unknown {
    const obj: any = {};
    message.schema !== undefined && (obj.schema = message.schema);
    message.tableName !== undefined && (obj.tableName = message.tableName);
    obj.partitionCondition = {};
    if (message.partitionCondition) {
      Object.entries(message.partitionCondition).forEach(([k, v]) => {
        obj.partitionCondition[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<HiveSource>): HiveSource {
    const message = { ...baseHiveSource } as HiveSource;
    message.partitionCondition = {};
    if (object.schema !== undefined && object.schema !== null) {
      message.schema = object.schema;
    } else {
      message.schema = '';
    }
    if (object.tableName !== undefined && object.tableName !== null) {
      message.tableName = object.tableName;
    } else {
      message.tableName = '';
    }
    if (object.partitionCondition !== undefined && object.partitionCondition !== null) {
      Object.entries(object.partitionCondition).forEach(([key, value]) => {
        if (value !== undefined) {
          message.partitionCondition[key] = String(value);
        }
      });
    }
    return message;
  },
};

const baseHiveSource_PartitionConditionEntry: object = { key: '', value: '' };

export const HiveSource_PartitionConditionEntry = {
  encode(message: HiveSource_PartitionConditionEntry, writer: Writer = Writer.create()): Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): HiveSource_PartitionConditionEntry {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseHiveSource_PartitionConditionEntry,
    } as HiveSource_PartitionConditionEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HiveSource_PartitionConditionEntry {
    const message = {
      ...baseHiveSource_PartitionConditionEntry,
    } as HiveSource_PartitionConditionEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = '';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = '';
    }
    return message;
  },

  toJSON(message: HiveSource_PartitionConditionEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<HiveSource_PartitionConditionEntry>
  ): HiveSource_PartitionConditionEntry {
    const message = {
      ...baseHiveSource_PartitionConditionEntry,
    } as HiveSource_PartitionConditionEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = '';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = '';
    }
    return message;
  },
};

const baseFieldMapping: object = {
  patientIdentifierField: '',
  providerIdentifierField: '',
  dateFields: '',
  dateWindowStartField: '',
  dateWindowEndField: '',
  ageField: '',
  yearOfBirthField: '',
  genderField: '',
  bmiField: '',
  bmiMeasurementField: '',
  patientStateField: '',
  patientZip3Field: '',
  providerStateField: '',
  providerZip3Field: '',
  providerSpecialtyField: '',
  procedureField: '',
  labField: '',
  drugField: '',
  diagnosisField: '',
  diagnosisQualField: '',
  genericMedicationField: '',
  datafeedIdField: '',
};

export const FieldMapping = {
  encode(message: FieldMapping, writer: Writer = Writer.create()): Writer {
    if (message.patientIdentifierField !== '') {
      writer.uint32(10).string(message.patientIdentifierField);
    }
    if (message.providerIdentifierField !== '') {
      writer.uint32(18).string(message.providerIdentifierField);
    }
    for (const v of message.dateFields) {
      writer.uint32(26).string(v!);
    }
    if (message.dateWindowStartField !== '') {
      writer.uint32(34).string(message.dateWindowStartField);
    }
    if (message.dateWindowEndField !== '') {
      writer.uint32(42).string(message.dateWindowEndField);
    }
    if (message.ageField !== '') {
      writer.uint32(50).string(message.ageField);
    }
    if (message.yearOfBirthField !== '') {
      writer.uint32(58).string(message.yearOfBirthField);
    }
    if (message.genderField !== '') {
      writer.uint32(66).string(message.genderField);
    }
    if (message.bmiField !== '') {
      writer.uint32(74).string(message.bmiField);
    }
    if (message.bmiMeasurementField !== '') {
      writer.uint32(82).string(message.bmiMeasurementField);
    }
    if (message.patientStateField !== '') {
      writer.uint32(90).string(message.patientStateField);
    }
    if (message.patientZip3Field !== '') {
      writer.uint32(98).string(message.patientZip3Field);
    }
    if (message.providerStateField !== '') {
      writer.uint32(106).string(message.providerStateField);
    }
    if (message.providerZip3Field !== '') {
      writer.uint32(114).string(message.providerZip3Field);
    }
    if (message.providerSpecialtyField !== '') {
      writer.uint32(122).string(message.providerSpecialtyField);
    }
    if (message.procedureField !== '') {
      writer.uint32(130).string(message.procedureField);
    }
    if (message.labField !== '') {
      writer.uint32(138).string(message.labField);
    }
    if (message.drugField !== '') {
      writer.uint32(146).string(message.drugField);
    }
    if (message.diagnosisField !== '') {
      writer.uint32(154).string(message.diagnosisField);
    }
    if (message.diagnosisQualField !== '') {
      writer.uint32(162).string(message.diagnosisQualField);
    }
    if (message.genericMedicationField !== '') {
      writer.uint32(170).string(message.genericMedicationField);
    }
    if (message.datafeedIdField !== '') {
      writer.uint32(178).string(message.datafeedIdField);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): FieldMapping {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseFieldMapping } as FieldMapping;
    message.dateFields = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.patientIdentifierField = reader.string();
          break;
        case 2:
          message.providerIdentifierField = reader.string();
          break;
        case 3:
          message.dateFields.push(reader.string());
          break;
        case 4:
          message.dateWindowStartField = reader.string();
          break;
        case 5:
          message.dateWindowEndField = reader.string();
          break;
        case 6:
          message.ageField = reader.string();
          break;
        case 7:
          message.yearOfBirthField = reader.string();
          break;
        case 8:
          message.genderField = reader.string();
          break;
        case 9:
          message.bmiField = reader.string();
          break;
        case 10:
          message.bmiMeasurementField = reader.string();
          break;
        case 11:
          message.patientStateField = reader.string();
          break;
        case 12:
          message.patientZip3Field = reader.string();
          break;
        case 13:
          message.providerStateField = reader.string();
          break;
        case 14:
          message.providerZip3Field = reader.string();
          break;
        case 15:
          message.providerSpecialtyField = reader.string();
          break;
        case 16:
          message.procedureField = reader.string();
          break;
        case 17:
          message.labField = reader.string();
          break;
        case 18:
          message.drugField = reader.string();
          break;
        case 19:
          message.diagnosisField = reader.string();
          break;
        case 20:
          message.diagnosisQualField = reader.string();
          break;
        case 21:
          message.genericMedicationField = reader.string();
          break;
        case 22:
          message.datafeedIdField = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FieldMapping {
    const message = { ...baseFieldMapping } as FieldMapping;
    message.dateFields = [];
    if (object.patientIdentifierField !== undefined && object.patientIdentifierField !== null) {
      message.patientIdentifierField = String(object.patientIdentifierField);
    } else {
      message.patientIdentifierField = '';
    }
    if (object.providerIdentifierField !== undefined && object.providerIdentifierField !== null) {
      message.providerIdentifierField = String(object.providerIdentifierField);
    } else {
      message.providerIdentifierField = '';
    }
    if (object.dateFields !== undefined && object.dateFields !== null) {
      for (const e of object.dateFields) {
        message.dateFields.push(String(e));
      }
    }
    if (object.dateWindowStartField !== undefined && object.dateWindowStartField !== null) {
      message.dateWindowStartField = String(object.dateWindowStartField);
    } else {
      message.dateWindowStartField = '';
    }
    if (object.dateWindowEndField !== undefined && object.dateWindowEndField !== null) {
      message.dateWindowEndField = String(object.dateWindowEndField);
    } else {
      message.dateWindowEndField = '';
    }
    if (object.ageField !== undefined && object.ageField !== null) {
      message.ageField = String(object.ageField);
    } else {
      message.ageField = '';
    }
    if (object.yearOfBirthField !== undefined && object.yearOfBirthField !== null) {
      message.yearOfBirthField = String(object.yearOfBirthField);
    } else {
      message.yearOfBirthField = '';
    }
    if (object.genderField !== undefined && object.genderField !== null) {
      message.genderField = String(object.genderField);
    } else {
      message.genderField = '';
    }
    if (object.bmiField !== undefined && object.bmiField !== null) {
      message.bmiField = String(object.bmiField);
    } else {
      message.bmiField = '';
    }
    if (object.bmiMeasurementField !== undefined && object.bmiMeasurementField !== null) {
      message.bmiMeasurementField = String(object.bmiMeasurementField);
    } else {
      message.bmiMeasurementField = '';
    }
    if (object.patientStateField !== undefined && object.patientStateField !== null) {
      message.patientStateField = String(object.patientStateField);
    } else {
      message.patientStateField = '';
    }
    if (object.patientZip3Field !== undefined && object.patientZip3Field !== null) {
      message.patientZip3Field = String(object.patientZip3Field);
    } else {
      message.patientZip3Field = '';
    }
    if (object.providerStateField !== undefined && object.providerStateField !== null) {
      message.providerStateField = String(object.providerStateField);
    } else {
      message.providerStateField = '';
    }
    if (object.providerZip3Field !== undefined && object.providerZip3Field !== null) {
      message.providerZip3Field = String(object.providerZip3Field);
    } else {
      message.providerZip3Field = '';
    }
    if (object.providerSpecialtyField !== undefined && object.providerSpecialtyField !== null) {
      message.providerSpecialtyField = String(object.providerSpecialtyField);
    } else {
      message.providerSpecialtyField = '';
    }
    if (object.procedureField !== undefined && object.procedureField !== null) {
      message.procedureField = String(object.procedureField);
    } else {
      message.procedureField = '';
    }
    if (object.labField !== undefined && object.labField !== null) {
      message.labField = String(object.labField);
    } else {
      message.labField = '';
    }
    if (object.drugField !== undefined && object.drugField !== null) {
      message.drugField = String(object.drugField);
    } else {
      message.drugField = '';
    }
    if (object.diagnosisField !== undefined && object.diagnosisField !== null) {
      message.diagnosisField = String(object.diagnosisField);
    } else {
      message.diagnosisField = '';
    }
    if (object.diagnosisQualField !== undefined && object.diagnosisQualField !== null) {
      message.diagnosisQualField = String(object.diagnosisQualField);
    } else {
      message.diagnosisQualField = '';
    }
    if (object.genericMedicationField !== undefined && object.genericMedicationField !== null) {
      message.genericMedicationField = String(object.genericMedicationField);
    } else {
      message.genericMedicationField = '';
    }
    if (object.datafeedIdField !== undefined && object.datafeedIdField !== null) {
      message.datafeedIdField = String(object.datafeedIdField);
    } else {
      message.datafeedIdField = '';
    }
    return message;
  },

  toJSON(message: FieldMapping): unknown {
    const obj: any = {};
    message.patientIdentifierField !== undefined &&
      (obj.patientIdentifierField = message.patientIdentifierField);
    message.providerIdentifierField !== undefined &&
      (obj.providerIdentifierField = message.providerIdentifierField);
    if (message.dateFields) {
      obj.dateFields = message.dateFields.map((e) => e);
    } else {
      obj.dateFields = [];
    }
    message.dateWindowStartField !== undefined &&
      (obj.dateWindowStartField = message.dateWindowStartField);
    message.dateWindowEndField !== undefined &&
      (obj.dateWindowEndField = message.dateWindowEndField);
    message.ageField !== undefined && (obj.ageField = message.ageField);
    message.yearOfBirthField !== undefined && (obj.yearOfBirthField = message.yearOfBirthField);
    message.genderField !== undefined && (obj.genderField = message.genderField);
    message.bmiField !== undefined && (obj.bmiField = message.bmiField);
    message.bmiMeasurementField !== undefined &&
      (obj.bmiMeasurementField = message.bmiMeasurementField);
    message.patientStateField !== undefined && (obj.patientStateField = message.patientStateField);
    message.patientZip3Field !== undefined && (obj.patientZip3Field = message.patientZip3Field);
    message.providerStateField !== undefined &&
      (obj.providerStateField = message.providerStateField);
    message.providerZip3Field !== undefined && (obj.providerZip3Field = message.providerZip3Field);
    message.providerSpecialtyField !== undefined &&
      (obj.providerSpecialtyField = message.providerSpecialtyField);
    message.procedureField !== undefined && (obj.procedureField = message.procedureField);
    message.labField !== undefined && (obj.labField = message.labField);
    message.drugField !== undefined && (obj.drugField = message.drugField);
    message.diagnosisField !== undefined && (obj.diagnosisField = message.diagnosisField);
    message.diagnosisQualField !== undefined &&
      (obj.diagnosisQualField = message.diagnosisQualField);
    message.genericMedicationField !== undefined &&
      (obj.genericMedicationField = message.genericMedicationField);
    message.datafeedIdField !== undefined && (obj.datafeedIdField = message.datafeedIdField);
    return obj;
  },

  fromPartial(object: DeepPartial<FieldMapping>): FieldMapping {
    const message = { ...baseFieldMapping } as FieldMapping;
    message.dateFields = [];
    if (object.patientIdentifierField !== undefined && object.patientIdentifierField !== null) {
      message.patientIdentifierField = object.patientIdentifierField;
    } else {
      message.patientIdentifierField = '';
    }
    if (object.providerIdentifierField !== undefined && object.providerIdentifierField !== null) {
      message.providerIdentifierField = object.providerIdentifierField;
    } else {
      message.providerIdentifierField = '';
    }
    if (object.dateFields !== undefined && object.dateFields !== null) {
      for (const e of object.dateFields) {
        message.dateFields.push(e);
      }
    }
    if (object.dateWindowStartField !== undefined && object.dateWindowStartField !== null) {
      message.dateWindowStartField = object.dateWindowStartField;
    } else {
      message.dateWindowStartField = '';
    }
    if (object.dateWindowEndField !== undefined && object.dateWindowEndField !== null) {
      message.dateWindowEndField = object.dateWindowEndField;
    } else {
      message.dateWindowEndField = '';
    }
    if (object.ageField !== undefined && object.ageField !== null) {
      message.ageField = object.ageField;
    } else {
      message.ageField = '';
    }
    if (object.yearOfBirthField !== undefined && object.yearOfBirthField !== null) {
      message.yearOfBirthField = object.yearOfBirthField;
    } else {
      message.yearOfBirthField = '';
    }
    if (object.genderField !== undefined && object.genderField !== null) {
      message.genderField = object.genderField;
    } else {
      message.genderField = '';
    }
    if (object.bmiField !== undefined && object.bmiField !== null) {
      message.bmiField = object.bmiField;
    } else {
      message.bmiField = '';
    }
    if (object.bmiMeasurementField !== undefined && object.bmiMeasurementField !== null) {
      message.bmiMeasurementField = object.bmiMeasurementField;
    } else {
      message.bmiMeasurementField = '';
    }
    if (object.patientStateField !== undefined && object.patientStateField !== null) {
      message.patientStateField = object.patientStateField;
    } else {
      message.patientStateField = '';
    }
    if (object.patientZip3Field !== undefined && object.patientZip3Field !== null) {
      message.patientZip3Field = object.patientZip3Field;
    } else {
      message.patientZip3Field = '';
    }
    if (object.providerStateField !== undefined && object.providerStateField !== null) {
      message.providerStateField = object.providerStateField;
    } else {
      message.providerStateField = '';
    }
    if (object.providerZip3Field !== undefined && object.providerZip3Field !== null) {
      message.providerZip3Field = object.providerZip3Field;
    } else {
      message.providerZip3Field = '';
    }
    if (object.providerSpecialtyField !== undefined && object.providerSpecialtyField !== null) {
      message.providerSpecialtyField = object.providerSpecialtyField;
    } else {
      message.providerSpecialtyField = '';
    }
    if (object.procedureField !== undefined && object.procedureField !== null) {
      message.procedureField = object.procedureField;
    } else {
      message.procedureField = '';
    }
    if (object.labField !== undefined && object.labField !== null) {
      message.labField = object.labField;
    } else {
      message.labField = '';
    }
    if (object.drugField !== undefined && object.drugField !== null) {
      message.drugField = object.drugField;
    } else {
      message.drugField = '';
    }
    if (object.diagnosisField !== undefined && object.diagnosisField !== null) {
      message.diagnosisField = object.diagnosisField;
    } else {
      message.diagnosisField = '';
    }
    if (object.diagnosisQualField !== undefined && object.diagnosisQualField !== null) {
      message.diagnosisQualField = object.diagnosisQualField;
    } else {
      message.diagnosisQualField = '';
    }
    if (object.genericMedicationField !== undefined && object.genericMedicationField !== null) {
      message.genericMedicationField = object.genericMedicationField;
    } else {
      message.genericMedicationField = '';
    }
    if (object.datafeedIdField !== undefined && object.datafeedIdField !== null) {
      message.datafeedIdField = object.datafeedIdField;
    } else {
      message.datafeedIdField = '';
    }
    return message;
  },
};

const baseDataModel: object = { dataType: '' };

export const DataModel = {
  encode(message: DataModel, writer: Writer = Writer.create()): Writer {
    if (message.dataType !== '') {
      writer.uint32(10).string(message.dataType);
    }
    if (message.fileSource !== undefined) {
      FileSource.encode(message.fileSource, writer.uint32(18).fork()).ldelim();
    }
    if (message.hiveSource !== undefined) {
      HiveSource.encode(message.hiveSource, writer.uint32(26).fork()).ldelim();
    }
    if (message.statsConfig !== undefined) {
      DataModel_StatsConfig.encode(message.statsConfig, writer.uint32(34).fork()).ldelim();
    }
    if (message.hllConfig !== undefined) {
      DataModel_HLLConfig.encode(message.hllConfig, writer.uint32(42).fork()).ldelim();
    }
    if (message.fieldMapping !== undefined) {
      FieldMapping.encode(message.fieldMapping, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataModel {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDataModel } as DataModel;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dataType = reader.string();
          break;
        case 2:
          message.fileSource = FileSource.decode(reader, reader.uint32());
          break;
        case 3:
          message.hiveSource = HiveSource.decode(reader, reader.uint32());
          break;
        case 4:
          message.statsConfig = DataModel_StatsConfig.decode(reader, reader.uint32());
          break;
        case 5:
          message.hllConfig = DataModel_HLLConfig.decode(reader, reader.uint32());
          break;
        case 6:
          message.fieldMapping = FieldMapping.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataModel {
    const message = { ...baseDataModel } as DataModel;
    if (object.dataType !== undefined && object.dataType !== null) {
      message.dataType = String(object.dataType);
    } else {
      message.dataType = '';
    }
    if (object.fileSource !== undefined && object.fileSource !== null) {
      message.fileSource = FileSource.fromJSON(object.fileSource);
    } else {
      message.fileSource = undefined;
    }
    if (object.hiveSource !== undefined && object.hiveSource !== null) {
      message.hiveSource = HiveSource.fromJSON(object.hiveSource);
    } else {
      message.hiveSource = undefined;
    }
    if (object.statsConfig !== undefined && object.statsConfig !== null) {
      message.statsConfig = DataModel_StatsConfig.fromJSON(object.statsConfig);
    } else {
      message.statsConfig = undefined;
    }
    if (object.hllConfig !== undefined && object.hllConfig !== null) {
      message.hllConfig = DataModel_HLLConfig.fromJSON(object.hllConfig);
    } else {
      message.hllConfig = undefined;
    }
    if (object.fieldMapping !== undefined && object.fieldMapping !== null) {
      message.fieldMapping = FieldMapping.fromJSON(object.fieldMapping);
    } else {
      message.fieldMapping = undefined;
    }
    return message;
  },

  toJSON(message: DataModel): unknown {
    const obj: any = {};
    message.dataType !== undefined && (obj.dataType = message.dataType);
    message.fileSource !== undefined &&
      (obj.fileSource = message.fileSource ? FileSource.toJSON(message.fileSource) : undefined);
    message.hiveSource !== undefined &&
      (obj.hiveSource = message.hiveSource ? HiveSource.toJSON(message.hiveSource) : undefined);
    message.statsConfig !== undefined &&
      (obj.statsConfig = message.statsConfig
        ? DataModel_StatsConfig.toJSON(message.statsConfig)
        : undefined);
    message.hllConfig !== undefined &&
      (obj.hllConfig = message.hllConfig
        ? DataModel_HLLConfig.toJSON(message.hllConfig)
        : undefined);
    message.fieldMapping !== undefined &&
      (obj.fieldMapping = message.fieldMapping
        ? FieldMapping.toJSON(message.fieldMapping)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<DataModel>): DataModel {
    const message = { ...baseDataModel } as DataModel;
    if (object.dataType !== undefined && object.dataType !== null) {
      message.dataType = object.dataType;
    } else {
      message.dataType = '';
    }
    if (object.fileSource !== undefined && object.fileSource !== null) {
      message.fileSource = FileSource.fromPartial(object.fileSource);
    } else {
      message.fileSource = undefined;
    }
    if (object.hiveSource !== undefined && object.hiveSource !== null) {
      message.hiveSource = HiveSource.fromPartial(object.hiveSource);
    } else {
      message.hiveSource = undefined;
    }
    if (object.statsConfig !== undefined && object.statsConfig !== null) {
      message.statsConfig = DataModel_StatsConfig.fromPartial(object.statsConfig);
    } else {
      message.statsConfig = undefined;
    }
    if (object.hllConfig !== undefined && object.hllConfig !== null) {
      message.hllConfig = DataModel_HLLConfig.fromPartial(object.hllConfig);
    } else {
      message.hllConfig = undefined;
    }
    if (object.fieldMapping !== undefined && object.fieldMapping !== null) {
      message.fieldMapping = FieldMapping.fromPartial(object.fieldMapping);
    } else {
      message.fieldMapping = undefined;
    }
    return message;
  },
};

const baseDataModel_StatsConfig: object = {
  topValues: false,
  fillRate: false,
  longitudinality: false,
  yearOverYear: false,
  keyStats: false,
  recordField: '',
};

export const DataModel_StatsConfig = {
  encode(message: DataModel_StatsConfig, writer: Writer = Writer.create()): Writer {
    if (message.topValues === true) {
      writer.uint32(8).bool(message.topValues);
    }
    if (message.fillRate === true) {
      writer.uint32(16).bool(message.fillRate);
    }
    if (message.longitudinality === true) {
      writer.uint32(24).bool(message.longitudinality);
    }
    if (message.yearOverYear === true) {
      writer.uint32(32).bool(message.yearOverYear);
    }
    if (message.keyStats === true) {
      writer.uint32(40).bool(message.keyStats);
    }
    if (message.recordField !== '') {
      writer.uint32(50).string(message.recordField);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataModel_StatsConfig {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDataModel_StatsConfig } as DataModel_StatsConfig;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.topValues = reader.bool();
          break;
        case 2:
          message.fillRate = reader.bool();
          break;
        case 3:
          message.longitudinality = reader.bool();
          break;
        case 4:
          message.yearOverYear = reader.bool();
          break;
        case 5:
          message.keyStats = reader.bool();
          break;
        case 6:
          message.recordField = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataModel_StatsConfig {
    const message = { ...baseDataModel_StatsConfig } as DataModel_StatsConfig;
    if (object.topValues !== undefined && object.topValues !== null) {
      message.topValues = Boolean(object.topValues);
    } else {
      message.topValues = false;
    }
    if (object.fillRate !== undefined && object.fillRate !== null) {
      message.fillRate = Boolean(object.fillRate);
    } else {
      message.fillRate = false;
    }
    if (object.longitudinality !== undefined && object.longitudinality !== null) {
      message.longitudinality = Boolean(object.longitudinality);
    } else {
      message.longitudinality = false;
    }
    if (object.yearOverYear !== undefined && object.yearOverYear !== null) {
      message.yearOverYear = Boolean(object.yearOverYear);
    } else {
      message.yearOverYear = false;
    }
    if (object.keyStats !== undefined && object.keyStats !== null) {
      message.keyStats = Boolean(object.keyStats);
    } else {
      message.keyStats = false;
    }
    if (object.recordField !== undefined && object.recordField !== null) {
      message.recordField = String(object.recordField);
    } else {
      message.recordField = '';
    }
    return message;
  },

  toJSON(message: DataModel_StatsConfig): unknown {
    const obj: any = {};
    message.topValues !== undefined && (obj.topValues = message.topValues);
    message.fillRate !== undefined && (obj.fillRate = message.fillRate);
    message.longitudinality !== undefined && (obj.longitudinality = message.longitudinality);
    message.yearOverYear !== undefined && (obj.yearOverYear = message.yearOverYear);
    message.keyStats !== undefined && (obj.keyStats = message.keyStats);
    message.recordField !== undefined && (obj.recordField = message.recordField);
    return obj;
  },

  fromPartial(object: DeepPartial<DataModel_StatsConfig>): DataModel_StatsConfig {
    const message = { ...baseDataModel_StatsConfig } as DataModel_StatsConfig;
    if (object.topValues !== undefined && object.topValues !== null) {
      message.topValues = object.topValues;
    } else {
      message.topValues = false;
    }
    if (object.fillRate !== undefined && object.fillRate !== null) {
      message.fillRate = object.fillRate;
    } else {
      message.fillRate = false;
    }
    if (object.longitudinality !== undefined && object.longitudinality !== null) {
      message.longitudinality = object.longitudinality;
    } else {
      message.longitudinality = false;
    }
    if (object.yearOverYear !== undefined && object.yearOverYear !== null) {
      message.yearOverYear = object.yearOverYear;
    } else {
      message.yearOverYear = false;
    }
    if (object.keyStats !== undefined && object.keyStats !== null) {
      message.keyStats = object.keyStats;
    } else {
      message.keyStats = false;
    }
    if (object.recordField !== undefined && object.recordField !== null) {
      message.recordField = object.recordField;
    } else {
      message.recordField = '';
    }
    return message;
  },
};

const baseDataModel_HLLConfig: object = { hllModelName: '' };

export const DataModel_HLLConfig = {
  encode(message: DataModel_HLLConfig, writer: Writer = Writer.create()): Writer {
    if (message.hllModelName !== '') {
      writer.uint32(10).string(message.hllModelName);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataModel_HLLConfig {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDataModel_HLLConfig } as DataModel_HLLConfig;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hllModelName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataModel_HLLConfig {
    const message = { ...baseDataModel_HLLConfig } as DataModel_HLLConfig;
    if (object.hllModelName !== undefined && object.hllModelName !== null) {
      message.hllModelName = String(object.hllModelName);
    } else {
      message.hllModelName = '';
    }
    return message;
  },

  toJSON(message: DataModel_HLLConfig): unknown {
    const obj: any = {};
    message.hllModelName !== undefined && (obj.hllModelName = message.hllModelName);
    return obj;
  },

  fromPartial(object: DeepPartial<DataModel_HLLConfig>): DataModel_HLLConfig {
    const message = { ...baseDataModel_HLLConfig } as DataModel_HLLConfig;
    if (object.hllModelName !== undefined && object.hllModelName !== null) {
      message.hllModelName = object.hllModelName;
    } else {
      message.hllModelName = '';
    }
    return message;
  },
};

const baseDataFeedConfig: object = {
  id: '',
  name: '',
  entities: 0,
  ignoreStartDate: false,
  ignoreEndDate: false,
  includeNullDates: false,
  earliestDate: '',
};

export const DataFeedConfig = {
  encode(message: DataFeedConfig, writer: Writer = Writer.create()): Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.models) {
      DataModel.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    writer.uint32(34).fork();
    for (const v of message.entities) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.ignoreStartDate === true) {
      writer.uint32(40).bool(message.ignoreStartDate);
    }
    if (message.ignoreEndDate === true) {
      writer.uint32(48).bool(message.ignoreEndDate);
    }
    if (message.includeNullDates === true) {
      writer.uint32(56).bool(message.includeNullDates);
    }
    if (message.earliestDate !== '') {
      writer.uint32(66).string(message.earliestDate);
    }
    if (message.dataLag !== undefined) {
      DataFeedConfig_DataLag.encode(message.dataLag, writer.uint32(82).fork()).ldelim();
    }
    if (message.refreshConfig !== undefined) {
      DataFeedConfig_RefreshConfig.encode(message.refreshConfig, writer.uint32(90).fork()).ldelim();
    }
    if (message.hllConfig !== undefined) {
      DataFeedConfig_HLLConfig.encode(message.hllConfig, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataFeedConfig {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDataFeedConfig } as DataFeedConfig;
    message.models = [];
    message.entities = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.models.push(DataModel.decode(reader, reader.uint32()));
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.entities.push(reader.int32() as any);
            }
          } else {
            message.entities.push(reader.int32() as any);
          }
          break;
        case 5:
          message.ignoreStartDate = reader.bool();
          break;
        case 6:
          message.ignoreEndDate = reader.bool();
          break;
        case 7:
          message.includeNullDates = reader.bool();
          break;
        case 8:
          message.earliestDate = reader.string();
          break;
        case 10:
          message.dataLag = DataFeedConfig_DataLag.decode(reader, reader.uint32());
          break;
        case 11:
          message.refreshConfig = DataFeedConfig_RefreshConfig.decode(reader, reader.uint32());
          break;
        case 9:
          message.hllConfig = DataFeedConfig_HLLConfig.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataFeedConfig {
    const message = { ...baseDataFeedConfig } as DataFeedConfig;
    message.models = [];
    message.entities = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '';
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = '';
    }
    if (object.models !== undefined && object.models !== null) {
      for (const e of object.models) {
        message.models.push(DataModel.fromJSON(e));
      }
    }
    if (object.entities !== undefined && object.entities !== null) {
      for (const e of object.entities) {
        message.entities.push(entityFromJSON(e));
      }
    }
    if (object.ignoreStartDate !== undefined && object.ignoreStartDate !== null) {
      message.ignoreStartDate = Boolean(object.ignoreStartDate);
    } else {
      message.ignoreStartDate = false;
    }
    if (object.ignoreEndDate !== undefined && object.ignoreEndDate !== null) {
      message.ignoreEndDate = Boolean(object.ignoreEndDate);
    } else {
      message.ignoreEndDate = false;
    }
    if (object.includeNullDates !== undefined && object.includeNullDates !== null) {
      message.includeNullDates = Boolean(object.includeNullDates);
    } else {
      message.includeNullDates = false;
    }
    if (object.earliestDate !== undefined && object.earliestDate !== null) {
      message.earliestDate = String(object.earliestDate);
    } else {
      message.earliestDate = '';
    }
    if (object.dataLag !== undefined && object.dataLag !== null) {
      message.dataLag = DataFeedConfig_DataLag.fromJSON(object.dataLag);
    } else {
      message.dataLag = undefined;
    }
    if (object.refreshConfig !== undefined && object.refreshConfig !== null) {
      message.refreshConfig = DataFeedConfig_RefreshConfig.fromJSON(object.refreshConfig);
    } else {
      message.refreshConfig = undefined;
    }
    if (object.hllConfig !== undefined && object.hllConfig !== null) {
      message.hllConfig = DataFeedConfig_HLLConfig.fromJSON(object.hllConfig);
    } else {
      message.hllConfig = undefined;
    }
    return message;
  },

  toJSON(message: DataFeedConfig): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    if (message.models) {
      obj.models = message.models.map((e) => (e ? DataModel.toJSON(e) : undefined));
    } else {
      obj.models = [];
    }
    if (message.entities) {
      obj.entities = message.entities.map((e) => entityToJSON(e));
    } else {
      obj.entities = [];
    }
    message.ignoreStartDate !== undefined && (obj.ignoreStartDate = message.ignoreStartDate);
    message.ignoreEndDate !== undefined && (obj.ignoreEndDate = message.ignoreEndDate);
    message.includeNullDates !== undefined && (obj.includeNullDates = message.includeNullDates);
    message.earliestDate !== undefined && (obj.earliestDate = message.earliestDate);
    message.dataLag !== undefined &&
      (obj.dataLag = message.dataLag ? DataFeedConfig_DataLag.toJSON(message.dataLag) : undefined);
    message.refreshConfig !== undefined &&
      (obj.refreshConfig = message.refreshConfig
        ? DataFeedConfig_RefreshConfig.toJSON(message.refreshConfig)
        : undefined);
    message.hllConfig !== undefined &&
      (obj.hllConfig = message.hllConfig
        ? DataFeedConfig_HLLConfig.toJSON(message.hllConfig)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<DataFeedConfig>): DataFeedConfig {
    const message = { ...baseDataFeedConfig } as DataFeedConfig;
    message.models = [];
    message.entities = [];
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '';
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = '';
    }
    if (object.models !== undefined && object.models !== null) {
      for (const e of object.models) {
        message.models.push(DataModel.fromPartial(e));
      }
    }
    if (object.entities !== undefined && object.entities !== null) {
      for (const e of object.entities) {
        message.entities.push(e);
      }
    }
    if (object.ignoreStartDate !== undefined && object.ignoreStartDate !== null) {
      message.ignoreStartDate = object.ignoreStartDate;
    } else {
      message.ignoreStartDate = false;
    }
    if (object.ignoreEndDate !== undefined && object.ignoreEndDate !== null) {
      message.ignoreEndDate = object.ignoreEndDate;
    } else {
      message.ignoreEndDate = false;
    }
    if (object.includeNullDates !== undefined && object.includeNullDates !== null) {
      message.includeNullDates = object.includeNullDates;
    } else {
      message.includeNullDates = false;
    }
    if (object.earliestDate !== undefined && object.earliestDate !== null) {
      message.earliestDate = object.earliestDate;
    } else {
      message.earliestDate = '';
    }
    if (object.dataLag !== undefined && object.dataLag !== null) {
      message.dataLag = DataFeedConfig_DataLag.fromPartial(object.dataLag);
    } else {
      message.dataLag = undefined;
    }
    if (object.refreshConfig !== undefined && object.refreshConfig !== null) {
      message.refreshConfig = DataFeedConfig_RefreshConfig.fromPartial(object.refreshConfig);
    } else {
      message.refreshConfig = undefined;
    }
    if (object.hllConfig !== undefined && object.hllConfig !== null) {
      message.hllConfig = DataFeedConfig_HLLConfig.fromPartial(object.hllConfig);
    } else {
      message.hllConfig = undefined;
    }
    return message;
  },
};

const baseDataFeedConfig_DataLag: object = { amount: 0, interval: 0 };

export const DataFeedConfig_DataLag = {
  encode(message: DataFeedConfig_DataLag, writer: Writer = Writer.create()): Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int32(message.amount);
    }
    if (message.interval !== 0) {
      writer.uint32(16).int32(message.interval);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataFeedConfig_DataLag {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseDataFeedConfig_DataLag } as DataFeedConfig_DataLag;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.amount = reader.int32();
          break;
        case 2:
          message.interval = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataFeedConfig_DataLag {
    const message = { ...baseDataFeedConfig_DataLag } as DataFeedConfig_DataLag;
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = Number(object.amount);
    } else {
      message.amount = 0;
    }
    if (object.interval !== undefined && object.interval !== null) {
      message.interval = intervalFromJSON(object.interval);
    } else {
      message.interval = 0;
    }
    return message;
  },

  toJSON(message: DataFeedConfig_DataLag): unknown {
    const obj: any = {};
    message.amount !== undefined && (obj.amount = message.amount);
    message.interval !== undefined && (obj.interval = intervalToJSON(message.interval));
    return obj;
  },

  fromPartial(object: DeepPartial<DataFeedConfig_DataLag>): DataFeedConfig_DataLag {
    const message = { ...baseDataFeedConfig_DataLag } as DataFeedConfig_DataLag;
    if (object.amount !== undefined && object.amount !== null) {
      message.amount = object.amount;
    } else {
      message.amount = 0;
    }
    if (object.interval !== undefined && object.interval !== null) {
      message.interval = object.interval;
    } else {
      message.interval = 0;
    }
    return message;
  },
};

const baseDataFeedConfig_RefreshConfig: object = {
  rate: '',
  startDate: '',
  endDate: '',
};

export const DataFeedConfig_RefreshConfig = {
  encode(message: DataFeedConfig_RefreshConfig, writer: Writer = Writer.create()): Writer {
    if (message.rate !== '') {
      writer.uint32(10).string(message.rate);
    }
    if (message.startDate !== '') {
      writer.uint32(18).string(message.startDate);
    }
    if (message.endDate !== '') {
      writer.uint32(26).string(message.endDate);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataFeedConfig_RefreshConfig {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseDataFeedConfig_RefreshConfig,
    } as DataFeedConfig_RefreshConfig;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.rate = reader.string();
          break;
        case 2:
          message.startDate = reader.string();
          break;
        case 3:
          message.endDate = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataFeedConfig_RefreshConfig {
    const message = {
      ...baseDataFeedConfig_RefreshConfig,
    } as DataFeedConfig_RefreshConfig;
    if (object.rate !== undefined && object.rate !== null) {
      message.rate = String(object.rate);
    } else {
      message.rate = '';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = String(object.startDate);
    } else {
      message.startDate = '';
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = String(object.endDate);
    } else {
      message.endDate = '';
    }
    return message;
  },

  toJSON(message: DataFeedConfig_RefreshConfig): unknown {
    const obj: any = {};
    message.rate !== undefined && (obj.rate = message.rate);
    message.startDate !== undefined && (obj.startDate = message.startDate);
    message.endDate !== undefined && (obj.endDate = message.endDate);
    return obj;
  },

  fromPartial(object: DeepPartial<DataFeedConfig_RefreshConfig>): DataFeedConfig_RefreshConfig {
    const message = {
      ...baseDataFeedConfig_RefreshConfig,
    } as DataFeedConfig_RefreshConfig;
    if (object.rate !== undefined && object.rate !== null) {
      message.rate = object.rate;
    } else {
      message.rate = '';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = '';
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = '';
    }
    return message;
  },
};

const baseDataFeedConfig_HLLConfig: object = {
  partitions: 0,
  attributes: 0,
  privacyFiltering: false,
};

export const DataFeedConfig_HLLConfig = {
  encode(message: DataFeedConfig_HLLConfig, writer: Writer = Writer.create()): Writer {
    if (message.partitions !== 0) {
      writer.uint32(8).int32(message.partitions);
    }
    writer.uint32(18).fork();
    for (const v of message.attributes) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.privacyFiltering === true) {
      writer.uint32(24).bool(message.privacyFiltering);
    }
    return writer;
  },

  decode(input: Reader | Uint8Array, length?: number): DataFeedConfig_HLLConfig {
    const reader = input instanceof Reader ? input : new Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseDataFeedConfig_HLLConfig,
    } as DataFeedConfig_HLLConfig;
    message.attributes = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.partitions = reader.int32();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.attributes.push(reader.int32() as any);
            }
          } else {
            message.attributes.push(reader.int32() as any);
          }
          break;
        case 3:
          message.privacyFiltering = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DataFeedConfig_HLLConfig {
    const message = {
      ...baseDataFeedConfig_HLLConfig,
    } as DataFeedConfig_HLLConfig;
    message.attributes = [];
    if (object.partitions !== undefined && object.partitions !== null) {
      message.partitions = Number(object.partitions);
    } else {
      message.partitions = 0;
    }
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(attributeFromJSON(e));
      }
    }
    if (object.privacyFiltering !== undefined && object.privacyFiltering !== null) {
      message.privacyFiltering = Boolean(object.privacyFiltering);
    } else {
      message.privacyFiltering = false;
    }
    return message;
  },

  toJSON(message: DataFeedConfig_HLLConfig): unknown {
    const obj: any = {};
    message.partitions !== undefined && (obj.partitions = message.partitions);
    if (message.attributes) {
      obj.attributes = message.attributes.map((e) => attributeToJSON(e));
    } else {
      obj.attributes = [];
    }
    message.privacyFiltering !== undefined && (obj.privacyFiltering = message.privacyFiltering);
    return obj;
  },

  fromPartial(object: DeepPartial<DataFeedConfig_HLLConfig>): DataFeedConfig_HLLConfig {
    const message = {
      ...baseDataFeedConfig_HLLConfig,
    } as DataFeedConfig_HLLConfig;
    message.attributes = [];
    if (object.partitions !== undefined && object.partitions !== null) {
      message.partitions = object.partitions;
    } else {
      message.partitions = 0;
    }
    if (object.attributes !== undefined && object.attributes !== null) {
      for (const e of object.attributes) {
        message.attributes.push(e);
      }
    }
    if (object.privacyFiltering !== undefined && object.privacyFiltering !== null) {
      message.privacyFiltering = object.privacyFiltering;
    } else {
      message.privacyFiltering = false;
    }
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
