import React, { useState, KeyboardEvent, ChangeEvent } from 'react';

import './Search.scss';

import searchIcon from '../../images/search.svg';

type SearchProps = {
  submitSearch: () => void;
  search: string;
  setSearch: (search: string) => void;
};

/*
 * Renders search input box and executes submission logic
 */
export default function Search({ submitSearch, setSearch, search }: SearchProps) {
  const handleKeyPress = (e: KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitSearch();
    }
  };

  const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.placeholder = '';
    setFocused(true);
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.placeholder = 'Search by name or dataset ID';
    setFocused(false);
  };

  const [isFocused, setFocused] = useState(false);

  const containerClassName = isFocused ? 'search-form-focused' : 'search-form';

  return (
    <div className={containerClassName}>
      <img src={searchIcon} className="search-input-icon" />
      <input
        type="text"
        value={search}
        className="search-input"
        placeholder="Search by name or dataset ID"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
}
