import React, { useState, useEffect } from 'react';

import './Filters.scss';

type FiltersProps = {
  applySort: (param: string[][]) => void;
  applyFilter: (param: string[][]) => void;
  datasetCount: number;
};

/*
 * Component that displays sorts and filter options and triggers
 * actions passed from props
 */
export default function Filters({ applySort, applyFilter, datasetCount }: FiltersProps) {
  const defaultFilter: { [key: string]: boolean } = { active: true, pending: true };
  const [statusFilter, setStatusFilter] = useState(defaultFilter);

  useEffect(() => {
    const filter: string[][] = [];
    for (const id in statusFilter) {
      if (statusFilter[id]) {
        filter.push(['status', id]);
      }
    }
    applyFilter(filter);
  }, [statusFilter]);

  const sortsOnChage = (e: React.ChangeEvent<HTMLInputElement>) => {
    applySort([['sort', e.target.id]]);
  };

  const filtersOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusFilter({ ...statusFilter, [e.target.id]: e.target.checked });
  };

  return (
    <div className="filters-container">
      {/* Sort layer */}
      <div className="filter-container">
        <div className="filter-group-label">Sort by:</div>
        <div className="filter-group">
          <label>
            <input
              type={'radio'}
              id={'name'}
              name={'sort'}
              defaultChecked={true}
              onChange={sortsOnChage}
            />
            A-Z
          </label>

          <label>
            <input type={'radio'} id={'ingest_date'} name={'sort'} onChange={sortsOnChage} />
            Date added
          </label>

          <label>
            <input type={'radio'} id={'datatype'} name={'sort'} onChange={sortsOnChage} />
            Data type
          </label>
        </div>
      </div>

      {/* Status filter layer */}
      <div className="filter-container">
        <div className="filter-group-label">Show:</div>
        <div className="filter-group">
          <label>
            <input
              type={'checkbox'}
              id={'active'}
              name={'status'}
              onChange={filtersOnChange}
              defaultChecked={true}
            />
            Active
          </label>

          <label>
            <input
              type={'checkbox'}
              id={'pending'}
              name={'status'}
              onChange={filtersOnChange}
              defaultChecked={true}
            />
            Pending
          </label>

          <label>
            <input type={'checkbox'} id={'archived'} name={'status'} onChange={filtersOnChange} />
            Archived
          </label>
        </div>
      </div>
    </div>
  );
}
