import React, { useState } from 'react';

import './Rows.scss';

import { Dataset } from '../../types';
import { formatDatetime } from './Table';
import { DATA_TYPES } from '../../constants/add-data-constants';
import checkmark from '../../images/checkmark.svg';
import archived from '../../images/archived.svg';
import pending from '../../images/pending.svg';

const MAX_NAME_LENGTH = 28;

type NameProps = {
  name: string;
  type: string;
};

/*
 * Renders a special component for the "Name" column
 */
export function NameCell({ name, type }: NameProps) {
  const colorClass = 'name-pill-' + type.toLowerCase();

  const [showTooltip, setShowTooltip] = useState(false);

  const onEnter = (e: any) => {
    setShowTooltip(true);
  };

  const onLeave = (e: any) => {
    setShowTooltip(false);
  };

  const getDataType = (type: string) => {
    return DATA_TYPES.find(({ id }) => id == type)?.name;
  };

  return (
    <div className="name-pill-container" onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <div className={colorClass} />

      <div className="name-pill-text-container">
        <span className="name-pill-text">{name}</span>
      </div>

      {showTooltip && (
        <div className="name-pill-tooltip">
          <div className="tooltip-text">{getDataType(type)}</div>
        </div>
      )}
    </div>
  );
}

type StatusProps = {
  status: string;
};

/*
 * Renders a special component for the "Status" column
 */
export function StatusCell({ status }: StatusProps) {
  const statusMap: { [key: string]: any } = {
    Archived: archived,
    Pending: pending,
    Active: checkmark,
  };

  return (
    <div className="status-container">
      <img className="status-img" src={statusMap[status]} />
      <span className="status-text">{status}</span>
    </div>
  );
}

type LastRefreshCellProps = {
  dataset: Dataset;
  setRefreshHistoryModalDatafeed: (dataset: Dataset | null) => void;
};

/*
 * Renders a special component for the "Last refresh" column
 */
export function LastRefreshCell({ dataset, setRefreshHistoryModalDatafeed }: LastRefreshCellProps) {
  if (!dataset.refresh?.last_run || !isValidDate(dataset.refresh?.last_run?.date)) {
    return <>None</>;
  }

  return (
    <>
      {formatDatetime(dataset.refresh.last_run.date)}{' '}
      <a
        data-testid={`Row_refreshHistoryModalTrigger_${dataset.id}`}
        onClick={(e) => {
          e.stopPropagation();
          setRefreshHistoryModalDatafeed(dataset);
        }}
        style={{ cursor: 'pointer' }}
      >
        ({calculateDaysAgo(dataset.refresh.last_run.date)} days ago)
      </a>
    </>
  );
}

function isValidDate(datetime: string) {
  const datetimeDateObj = new Date(datetime + 'Z');
  return datetimeDateObj instanceof Date;
}

function calculateDaysAgo(lastRefreshDatetime: string) {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  const lastRefreshDate = new Date(lastRefreshDatetime + 'Z');
  return Math.round(Math.abs((today.getTime() - lastRefreshDate.getTime()) / oneDay));
}
