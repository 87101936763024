import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Dataset, Refresh } from '../../types';
import {
  Backend,
  AttributeStatementBackend,
  DatasetsResponse,
  ListRefreshRequest,
} from '../../backend';
import useDeepCompareEffect from '../../utils/deepCompareHook';

import plus from '../../images/plus.svg';
import venn from '../../images/min-venn.svg';

import './DatasetTable.scss';

import Heading from './Heading';
import Tabs from './Tabs';
import Filters from './Filters';
import Options from './Options';
import Table from './Table';
import GenericModal from '../Modal/GenericModal';
import RefreshHistoryModal from '../Modal/RefreshHistoryModal';

const HVM_LINK: string = process.env['REACT_APP_HVM_LINK'] || '';

const tabs: {
  name: string;
  params: string[][];
}[] = [
  { name: 'ALL DATA', params: [] },
  { name: 'PUBLIC MARKETPLACE', params: [['is_public', 'true']] },
  { name: 'PRIVATE MARKETPLACE', params: [['is_public', 'false']] },
];

const optionsConfig: {
  [key: string]: { title: string; body: string };
} = {
  active: {
    title: 'Activate data',
    body: 'This data will be published to HealthVerity Marketplace and will be available to permissioned users.',
  },
  archived: {
    title: 'Archive data',
    body: 'Archived data is removed from HealthVerity Marketplace and will become unavailable to users.',
  },
  pending: {
    title: 'Set to "Coming soon"',
    body: 'This data will appear as “coming soon” in HealthVerity Marketplace and will be visible to permissioned users.',
  },
};

type DatasetTableProps = {
  backend: Backend;
  attrStmtBackend: AttributeStatementBackend;
};

/**
 * Top level component in charge of managing navigation of tabs and search.
 *
 * Tabs: Initializes a list of objects that correspond to all the tabs and their
 * corresponding data categories. Each tab fetches rows based on its category
 *
 * Search: Adds a new tab upon valid search containing results from backend search.
 */
export default function DatasetTable({ backend, attrStmtBackend }: DatasetTableProps) {
  const searchTabIndex = tabs.length;
  const defaultTab = 0;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [submittedSearch, setSubmittedSearch] = useState('');
  const [sortParams, setSortParams] = useState([]);
  const [filterParams, setFilterParams] = useState([]);
  const [datasetCount, setDatasetCount] = useState(0);
  const [selectedDatasets, setSelectedDatasets] = useState<{ [id: number]: Dataset }>({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
  const [currentStatusUpdate, setCurrentStatusUpdate] = useState<string | null>(null);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [refreshConfigs, setRefreshConfigs] = useState<Refresh[]>([]);
  const [refreshHistoryModalDatafeed, setRefreshHistoryModalDatafeed] = useState<Dataset | null>(
    null
  );

  // This is kind of a hacky way to trigger a table refresh on command
  // By toggling between T/F it will cause the useEffect to re-trigger
  // and re-fetch datasets
  const [triggerTableRefresh, setTriggerTableRefresh] = useState(false);

  let tableRef: any;
  useEffect(() => {
    (async () => {
      const refreshResults: ListRefreshRequest = await attrStmtBackend.listRefreshConfigs();
      setRefreshConfigs(refreshResults.summaries);
    })();
  }, []);

  const activateTab = (tab: number) => {
    setActiveTab(tab);
  };

  const renderTables = tabs.map((tab, i) => {
    const { name, params } = tab;

    return (
      <TableWrapper
        key={name}
        backend={backend}
        params={[...params, ...filterParams, ...sortParams]}
        isActive={activeTab === i}
        setDatasetCount={setDatasetCount}
        setSelectedDatasets={setSelectedDatasets}
        selectedDatasets={new Set(Object.keys(selectedDatasets))}
        refresh={triggerTableRefresh}
        refreshConfigs={refreshConfigs}
        setRefreshHistoryModalDatafeed={setRefreshHistoryModalDatafeed}
      />
    );
  });

  const toggleSearch = (activate: boolean) => {
    let nextTab = activeTab;
    if (activate) {
      // focus search tab when entering search
      nextTab = searchTabIndex;
    } else {
      // reset tab focus when exiting search
      if (nextTab === searchTabIndex) {
        nextTab = defaultTab;
      }
    }

    setActiveSearch(activate);
    setActiveTab(nextTab);
  };

  const clearSearch = () => {
    setSearchInput('');
    toggleSearch(false);

    //TODO: query param
  };

  const submitSearch = () => {
    //TODO: query param
    setSubmittedSearch(searchInput);
    toggleSearch(true);
  };

  const applySort = (sort: any) => {
    //TODO: query param
    setSortParams(sort);
  };

  const applyFilter = (filter: any) => {
    //TODO: query param
    setFilterParams(filter);
  };

  const updateStatus = async () => {
    setIsUpdating(true);
    await Promise.all(
      Object.values(selectedDatasets).map(async (dataset) => {
        const { id, refresh, ...datasetUpdate } = dataset;
        return await backend.updateDataset(id!, { ...datasetUpdate, status: currentStatusUpdate! });
      })
    ).catch(() => {
      setShowFailureModal(true);
    });

    setIsUpdating(false);
    setTriggerTableRefresh(!triggerTableRefresh);
    setSelectedDatasets({});
  };

  return (
    <div className="contents">
      <div className="dataset-table-container">
        {/* Main heading */}
        <Heading submitSearch={submitSearch} search={searchInput} setSearch={setSearchInput} />

        {/* Tabs layer */}
        <div className="dataset-table-tabs">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            activateTab={activateTab}
            activeSearch={activeSearch}
            searchTabIndex={searchTabIndex}
            clearSearch={clearSearch}
          />

          {/* Action button layer */}
          <div className="dataset-table-action-buttons">
            <div
              onClick={() => (window.location.href = HVM_LINK + '/marketplace/workspace')}
              className="dataset-table-action-container"
            >
              <div className="dataset-table-action-btn-orange">
                <div className="dataset-table-action-icon-orange">
                  <img height="13" width="13" src={venn} />
                </div>

                <div className="dataset-table-action-text">
                  <span>Go to Workspace</span>
                </div>
              </div>
            </div>

            <div className="dataset-table-action-container">
              <div className="dataset-table-action-btn">
                <div className="dataset-table-action-icon">
                  <img height="13" width="13" src={plus} />
                </div>

                <Link to="/add-dataset" className="dataset-table-action-text">
                  <span>Add new data</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Extras layer */}
        <div className="dataset-table-extras-container">
          {Object.keys(selectedDatasets).length === 0 ? (
            <Filters applySort={applySort} applyFilter={applyFilter} datasetCount={datasetCount} />
          ) : (
            <Options
              isUpdating={isUpdating}
              onClick={(status) => {
                setCurrentStatusUpdate(status);
                setShowStatusUpdateModal(true);
              }}
            />
          )}
          <div className="dataset-count-container">
            <div>All data: {datasetCount} data feeds</div>
          </div>
        </div>

        {/* Default tables */}
        {refreshConfigs.length > 0 ? renderTables : <div className="table-loading" />}

        {/* Search table */}
        {activeSearch && refreshConfigs.length > 0 && (
          <TableWrapper
            params={[...sortParams, ...filterParams, ['search', submittedSearch]]}
            isActive={activeTab === searchTabIndex}
            backend={backend}
            search={submittedSearch.length > 0 ? submittedSearch : undefined}
            setDatasetCount={setDatasetCount}
            setSelectedDatasets={setSelectedDatasets}
            selectedDatasets={new Set(Object.keys(selectedDatasets))}
            refresh={triggerTableRefresh}
            refreshConfigs={refreshConfigs}
            setRefreshHistoryModalDatafeed={setRefreshHistoryModalDatafeed}
          />
        )}
      </div>

      {/* Refresh history modal */}
      {refreshHistoryModalDatafeed && (
        <RefreshHistoryModal
          dataset={refreshHistoryModalDatafeed}
          attrStmtBackend={attrStmtBackend}
          setRefreshHistoryModalDatafeed={setRefreshHistoryModalDatafeed}
        />
      )}

      {/* Status change confirm modal */}
      {currentStatusUpdate && (
        <GenericModal
          headerText={optionsConfig[currentStatusUpdate].title + '?'}
          show={showStatusUpdateModal}
          close={() => setShowStatusUpdateModal(false)}
          onSubmit={async () => {
            setShowStatusUpdateModal(false);
            await updateStatus();
          }}
          dismissButtonText={'Cancel'}
          confirmButtonText={optionsConfig[currentStatusUpdate].title}
          customFooterClassName={'generic-modal-footer-border'}
          customConfirmButtonClassName={
            currentStatusUpdate === 'archived' ? 'dataset-update-status-confirm-btn' : ''
          }
        >
          <div
            style={{ backgroundColor: currentStatusUpdate === 'archived' ? '#F5ECEC' : '#E8F5DE' }}
            className="dataset-update-status-modal-body-ctn"
          >
            <span> {optionsConfig[currentStatusUpdate].body} </span>
          </div>
        </GenericModal>
      )}

      {/* Failure on status update modal */}
      <GenericModal
        headerText={'Update failed'}
        show={showFailureModal}
        close={() => setShowFailureModal(false)}
        dismissButtonText={'Close'}
        hideSubmitButton
        customFooterClassName={'generic-modal-footer-border'}
      >
        <div
          style={{ backgroundColor: '#F5ECEC' }}
          className="dataset-update-status-modal-body-ctn"
        >
          <span>One or more dataset updates failed, please try again later.</span>
        </div>
      </GenericModal>
    </div>
  );
}

type TableWrapperProps = {
  params: string[][];
  isActive: boolean;
  backend: Backend;
  search?: string;
  setDatasetCount: (value: number) => void;
  selectedDatasets: Set<string>;
  setSelectedDatasets: Dispatch<SetStateAction<{ [id: number]: Dataset }>>;
  refresh: boolean;
  refreshConfigs: Refresh[];
  setRefreshHistoryModalDatafeed: Dispatch<SetStateAction<Dataset | null>>;
};

/**
 * Wrapper component that controls the fetching of data for a single table.
 * Uses special useEffect hook that does object value comparison instead of
 * reference comparison for dependencies.
 */
function TableWrapper({
  params,
  isActive,
  backend,
  search,
  setDatasetCount,
  selectedDatasets,
  setSelectedDatasets,
  refresh,
  refreshConfigs,
  setRefreshHistoryModalDatafeed,
}: TableWrapperProps) {
  let tableRef: any;
  const setTableRef = (ref: any) => {
    tableRef = ref;
    // tie bottom of table to bottom of screen responsively
    if (ref) {
      const heightOffset = window.pageYOffset + ref.getBoundingClientRect().top;
      ref.style.maxHeight = `calc(100vh - ${heightOffset}px - 40px)`;
    }
  };

  const [datasets, setDatasets] = useState<DatasetsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useDeepCompareEffect(() => {
    const ref = tableRef; // ensure ref makes it into async closure
    (async () => {
      setIsLoading(true);
      const results: DatasetsResponse = await backend.listDatasets(params);
      results.datasets = mergeRefreshData(results.datasets, refreshConfigs);
      setDatasets(results);
      setIsLoading(false);

      // reset scroll height after changing params
      if (ref) {
        ref.scrollTo(0, 0);
      }
    })();
  }, [params, refresh]);

  useEffect(() => {
    // Update total dataset count for active table
    if (datasets && isActive) {
      setDatasetCount(datasets.total);
    }
  }, [isActive, datasets]);

  const loadNextPage = async () => {
    const nextPage = datasets === null ? null : datasets.continuation_token;

    if (nextPage) {
      const nextPageParams = [...params, ['continuation_token', nextPage]];
      const results: DatasetsResponse = await backend.listDatasets(nextPageParams);

      results.datasets = mergeRefreshData(
        [...datasets!.datasets, ...results.datasets],
        refreshConfigs
      );
      setDatasets(results);
    }
  };

  const onDatasetSelect = (dataset: Dataset, checked: boolean) => {
    setSelectedDatasets((selected) => {
      if (checked) {
        selected[dataset.id!] = dataset;
      } else {
        delete selected[dataset.id!];
      }
      return { ...selected };
    });
  };

  const mergeRefreshData = (datasets: Dataset[], refreshConfigs: Refresh[]): Dataset[] => {
    return datasets.map((dataset) => ({
      refresh: refreshConfigs.find((refresh) => parseInt(refresh.id) === dataset.id),
      ...dataset,
    }));
  };

  return (
    <>
      {isActive && (
        <Table
          setTableRef={setTableRef}
          datasets={datasets ? datasets.datasets : null}
          loadNextPage={loadNextPage}
          search={search}
          onSelect={onDatasetSelect}
          selectedDatasets={selectedDatasets}
          setRefreshHistoryModalDatafeed={setRefreshHistoryModalDatafeed}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
