import React from 'react';
import ReactDOM from 'react-dom';
import CenterModal from './CenterModal';
import './CenterModal.scss';
import './RefreshHistoryModal.scss';
import './AutoscanningDataModal.scss';

/**
 * Component that renders the AutoscanningDataModal
 */
export default function AutoscanningDataModal() {
  // Main div that renders the contents of a page
  const modalRoot: any = document.getElementById('contents');
  // Create a domNode and add it to the modalRoot
  const domNode: any = document.createElement('div');
  modalRoot.appendChild(domNode);

  return ReactDOM.createPortal(
    <CenterModal show={true} close={() => {}}>
      <div data-testid="AutoscanningDataModal" className="generic-modal">
        <div className="modal-body">
          <div className="autoscan-modal-notification-box">Scanning your data source</div>
        </div>
      </div>
    </CenterModal>,
    domNode
  );
}
