import { IAuthenticator } from './auth';
import {
  Dataset,
  DataProvider,
  Member,
  User,
  Refresh,
  RefreshHistoryRequest,
  DataSourceValidationRequest,
} from '../types';
import { DataFeedConfig, FileFormat } from './proto/dataset_config';
import { FormikValues } from 'formik';
import { SOURCE_TYPE_S3 } from '../constants/add-data-constants';

export type DatasetsResponse = {
  datasets: Dataset[];
  total: number;
  continuation_token: string;
};

export type DatasetResponse = {
  dataset: Dataset;
};

export type CreateDatasetResponse = {
  dataset: {
    id: number;
  };
};

export type DataProviderResponse = {
  data_providers: DataProvider[];
};

export type UserResponse = {
  users: User[];
};

export type UpdateMembersRequest = {
  members: Member[];
};

export type ListRefreshRequest = {
  summaries: Refresh[];
};

export type FeedHistoryRequest = {
  requests: RefreshHistoryRequest[];
};

export type DatasetSourceFieldsListings = {
  fields: string[];
};

/**
 * Interface to handle Backend requests
 */
export interface IBackend {
  listDatasets: (params: string[][]) => Promise<DatasetsResponse>;
  listDataProviders: () => Promise<DataProviderResponse>;
  listUsers: (userSearchQuery: string) => Promise<UserResponse>;
  createDataset: (datasetPayload: Dataset) => Promise<CreateDatasetResponse>;
  updateDatasetMembers: (id: number, members: UpdateMembersRequest) => Promise<void>;
  updateDataset: (id: number, datasetPayload: Dataset) => Promise<void>;
}

/**
 * Facilitates the data exchange between
 * the frontend and backend. Once initialized with
 * a valid authenticator and the baseUrl of the app,
 * an instance of this class can be used to make
 * various requests to the endpoints.
 */
export class Backend implements IBackend {
  private authenticator: IAuthenticator;
  private baseUrl: string;

  constructor({ baseUrl, authenticator }: { baseUrl: string; authenticator: IAuthenticator }) {
    this.authenticator = authenticator;
    this.baseUrl = baseUrl;
  }

  /**
   * Makes a request to /datasets/id endpoint
   * with no parameters and expects a response
   * containing a single dataset with matching id
   */
  async getDataset(id: number): Promise<DatasetResponse | null> {
    const resp = await this.get(`datasets/${id}`);
    if (resp.status === 404) {
      return null;
    }
    await this.throwIfErrorResponse(resp);
    const json = await resp.json();
    json.dataset = this.cleanDataset(json.dataset)
    return json
  }

  /**
   * Makes a request to /datasets endpoint and expects a response
   * containing a list of datasets filtered by params
   */
  async listDatasets(params: string[][]): Promise<DatasetsResponse> {
    const resp = await this.get('datasets', params);

    await this.throwIfErrorResponse(resp);
    const json = await resp.json();
    return json;
  }

  /**
   * Makes a request to /dataProviders endpoint
   * with no parameters and expects a response
   * containing a list of data providers
   */
  async listDataProviders(): Promise<DataProviderResponse> {
    const resp = await this.get('dataProviders');
    await this.throwIfErrorResponse(resp);
    return await resp.json();
  }

  /**
   * Makes a request to /users endpoint
   * and returns a list of users that match
   * the input query
   */
  async listUsers(userSearchQuery: string): Promise<UserResponse> {
    const params = [['user_search', userSearchQuery]];
    const resp = await this.get('users', params);
    await this.throwIfErrorResponse(resp);
    return await resp.json();
  }

  /**
   * Makes a request to `/datasets` endpoint which creates
   * a dataset in the DB and returns and ID back if successful
   */
  async updateDataset(id: number, datasetPayload: Dataset): Promise<void> {
    delete datasetPayload['id'];
    delete datasetPayload['members'];
    const resp = await this.put(`datasets/${id}`, datasetPayload);
    await this.throwIfErrorResponse(resp);
  }

  /**
   * Makes a request to `/datasets` endpoint which creates
   * a dataset in the DB and returns and ID back if successful
   */
  async createDataset(datasetPayload: Dataset): Promise<CreateDatasetResponse> {
    const resp = await this.post('datasets', datasetPayload);
    await this.throwIfErrorResponse(resp);
    return resp.json();
  }

  /**
   * Makes a request to /datasets/{id}/members endpoint which
   * creates DatasetMembers for the dataset of given id
   */
  async updateDatasetMembers(id: number, members: UpdateMembersRequest): Promise<void> {
    const resp = await this.put('datasets/' + id + '/members', members);
    await this.throwIfErrorResponse(resp);
  }

  private async post(endpoint: string, payload: any = {}): Promise<Response> {
    const request = new Request(`${this.baseUrl}/${endpoint}`, {
      body: JSON.stringify(payload),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Request-Source': 'DSM'
      },
    });
    await this.authenticator.authenticateRequest(request);
    return fetch(request);
  }

  private async put(endpoint: string, payload: any = {}): Promise<Response> {
    const request = new Request(`${this.baseUrl}/${endpoint}`, {
      body: JSON.stringify(payload),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Request-Source': 'DSM'
      },
    });
    await this.authenticator.authenticateRequest(request);
    return fetch(request);
  }

  private async get(endpoint: string, params: string[][] = []): Promise<Response> {
    const url = this.createURL(`${this.baseUrl}/${endpoint}`, params);
    const request = new Request(url);
    await this.authenticator.authenticateRequest(request);
    return await fetch(request);
  }

  private async throwIfErrorResponse(resp: Response): Promise<void> {
    if (resp.status >= 400) {
      const text = await resp.text();
      throw new Error(`Bad API Response (${resp.status}): ${text}`);
    }
  }

  private createURL(url: string, params: string[][]): string {
    const urlWithParams = new URL(url);
    urlWithParams.search = new URLSearchParams(params).toString();
    return urlWithParams.toString();
  }

  private cleanDataset(dataset: any): Dataset {
    // rename tags field
    const { tag_ids, ...d } = dataset

    return {
      ...d,
      ...(tag_ids && { tags: tag_ids })
    }
  }
}

/**
 * Interface to handle Attribute Statement Generation backend requests
 */
export interface IAttrStmtBackend {
  saveDatasetConfig: (id: number, config: DataFeedConfig) => Promise<void>;
  getDataFeedConfig: (id: number) => Promise<DataFeedConfig | null>;
}

export class AttributeStatementBackend implements IAttrStmtBackend {
  private authenticator: IAuthenticator;
  private baseUrl: string;

  constructor({ baseUrl, authenticator }: { baseUrl: string; authenticator: IAuthenticator }) {
    this.authenticator = authenticator;
    this.baseUrl = baseUrl;
  }

  async saveDatasetConfig(id: number, config: DataFeedConfig): Promise<void> {
    config.id = id.toString();
    const resp = await this.post('saveDataFeedConfig', {
      config: this.serializeDataFeedConfig(config),
    });
    await this.throwIfErrorResponse(resp);
  }

  async getDataFeedConfig(id: number): Promise<DataFeedConfig | null> {
    const resp = await this.post('getDataFeedConfig', { feed_id: id.toString() });
    if (resp.status === 404) {
      return null;
    }
    await this.throwIfErrorResponse(resp);
    const { config }: { config: string } = await resp.json();
    return this.deserializeEncodedConfig(config);
  }

  async triggerHLLGeneration(id: number): Promise<void> {
    const resp = await this.post('requestNewRun', {
      datafeeds: [id.toString()],
      run_hlls: true,
      run_stats: false,
      priority: 20, // HIGH priority
      notes: 'Dataset management initiated run for datafeed ' + id.toString(),
    });
  }
  async listRefreshConfigs(): Promise<ListRefreshRequest> {
    const resp = await this.post('listRefreshConfigs');
    await this.throwIfErrorResponse(resp);
    return resp.json();
  }

  async getDataFeedRefreshHistory(id: number): Promise<FeedHistoryRequest> {
    // Note: Feed ID must a string!
    const resp = await this.post('getDataFeedRefreshHistory', { feed_id: id.toString() });
    await this.throwIfErrorResponse(resp);
    return resp.json();
  }

  async tableExists(tableName: string): Promise<DataSourceValidationRequest> {
    const resp = await this.post('tableExists', { table_name: tableName });
    await this.throwIfErrorResponse(resp);
    return await resp.json();
  }

  async s3PathExists(s3Path: string): Promise<DataSourceValidationRequest> {
    const resp = await this.post('s3PathExists', { path: s3Path });
    await this.throwIfErrorResponse(resp);
    return await resp.json();
  }

  async getDatasetSourceFields(
    sourceType: string,
    sourceLocation: string,
    fileFormat = FileFormat.JSON,
    delimiter = ''
  ): Promise<DatasetSourceFieldsListings> {
    let resp = null;

    if (sourceType === SOURCE_TYPE_S3) {
      resp = await this.post('getS3PathFields', {
        path: sourceLocation,
        file_type: fileFormat,
        delimiter: delimiter,
      });
    } else {
      resp = await this.post('getHiveFields', {
        table_name: sourceLocation,
      });
    }
    await this.throwIfErrorResponse(resp);
    return resp.json();
  }

  private async get(endpoint: string, params: string[][] = []): Promise<Response> {
    const request = new Request(`${this.baseUrl}/${endpoint}`);
    await this.authenticator.authenticateRequest(request);
    return await fetch(request);
  }

  private async post(endpoint: string, payload: any = {}): Promise<Response> {
    const request = new Request(`${this.baseUrl}/${endpoint}`, {
      body: JSON.stringify(payload),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    await this.authenticator.authenticateRequest(request);
    return await fetch(request);
  }

  private async throwIfErrorResponse(resp: Response): Promise<void> {
    if (resp.status >= 400) {
      const text = await resp.text();
      throw new Error(`Bad API Response (${resp.status}): ${text}`);
    }
  }

  private serializeDataFeedConfig(config: DataFeedConfig): string {
    const writer = DataFeedConfig.encode(config);
    const bytes = writer.finish() as unknown as number[];
    return btoa(String.fromCharCode.apply(null, bytes));
  }

  private deserializeEncodedConfig(encoded: string): DataFeedConfig {
    const binaryString = atob(encoded);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return DataFeedConfig.decode(bytes);
  }
}
