import React, { useState } from 'react';

import './ToggleSwitch.scss';

type ToggleSwitchProps = {
  setActive: (active: boolean) => void;
  active: boolean;
};

export default function ToggleSwitch({ setActive, active }: ToggleSwitchProps) {
  return (
    <div
      className={active ? 'toggle-container-active' : 'toggle-container'}
      onClick={() => setActive(!active)}
    >
      <div className={'dialog-button'} style={{ left: active ? '31px' : '4px' }} />
      <div className={active ? 'left' : 'right'}>{active ? 'Yes' : 'No'}</div>
    </div>
  );
}
