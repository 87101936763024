/**
 * Holds constants for "Add new data" workflow
 */

import { FormikValues } from 'formik';

import { FieldMappingType, Dataset } from '../types';
import { DataFeedConfig, FileFormat } from '../backend/proto/dataset_config';

const CURRENT_FULL_YEAR = new Date().getFullYear();
const MAX_AVAILABLE_YEAR = CURRENT_FULL_YEAR + 5;

/**
 * Validates S3 file path for the following constraints:
 * - string must start with `s3://`
 * - string must have a valid bucket name after the `s3://<valid_bucket>/
 * - the valid_bucket should be followed by a `/` forward slash
 * - Examples of a valid path:
 *   - "s3://folder/file.json"
 *   - "s3://folder/data_dir"
 * - Examples of an invalid path:
 *   - "s3://file.json"
 *   - "s3://folder"
 */
export const S3_PATH_VALIDATION_REGEX = '^s3:\\/\\/([^/]+)\\/([\\w\\W]+)';

/*
 * Validate Hive table format:
 * - catalog.schema.table
 * - schema.table
 */
export const HIVE_VALIDATION_REGEX = /^(?:(\w+)\.)?(\w+)\.(\w+)$/;

export const FORM_FIELD = {
  DATA_SOURCE_TYPE: 'dataSourceType',
  /* S3-Specific fields */
  DATA_SOURCE_S3: 'dataSourceS3',
  DATA_SOURCE_S3_FILEFORMAT: 'dataSourceS3FileFormat',
  DATA_SOURCE_S3_DELIMITER: 'dataSourceS3Delimiter',
  /* Hive-Specific fields */
  DATA_SOURCE_HIVE: 'dataSourceHive',
  DATA_SOURCE_HIVE_CONDITIONS: 'dataSourceHiveConditions',

  DATA_DESTINATION: 'dataDestination',
  EXPIRATION_MONTH: 'expirationMonth',
  EXPIRATION_YEAR: 'expirationYear',
  REFRESH_FREQUENCY: 'refreshFrequency',
  FIELD_MAPPING: 'fieldMapping',

  DATA_PROVIDER: 'dataProvider',
  DATA_TYPE: 'dataType',
  DATA_NAME: 'dataName',
  DESCRIPTION: 'dataDescription',
  AVAILABLE_MONTH: 'dataAvailableMonth',
  AVAILABLE_YEAR: 'dataAvailableYear',
  DATA_ADMINS: 'dataAdmins',
  DATA_TEAM_MEMBERS: 'dataTeamMembers',
};

// Form Fields that belong to page 1 (configure data flow)
export const CONFIGURE_DATA_FLOW_FORM_FIELDS = [
  FORM_FIELD.DATA_SOURCE_TYPE,
  FORM_FIELD.DATA_SOURCE_S3,
  FORM_FIELD.DATA_SOURCE_S3_FILEFORMAT,
  FORM_FIELD.DATA_SOURCE_S3_DELIMITER,
  FORM_FIELD.DATA_SOURCE_HIVE,
  FORM_FIELD.DATA_SOURCE_HIVE_CONDITIONS,
  FORM_FIELD.DATA_DESTINATION,
  FORM_FIELD.EXPIRATION_MONTH,
  FORM_FIELD.EXPIRATION_YEAR,
  FORM_FIELD.REFRESH_FREQUENCY,
];

// Form fields that belong to page 2 (provide data information)
export const PROVIDE_DATA_INFORMATION_FORM_FIELDS = [
  FORM_FIELD.DATA_PROVIDER,
  FORM_FIELD.FIELD_MAPPING,
  FORM_FIELD.DATA_TYPE,
  FORM_FIELD.DATA_NAME,
  FORM_FIELD.DESCRIPTION,
  FORM_FIELD.AVAILABLE_MONTH,
  FORM_FIELD.AVAILABLE_YEAR,
];

// Form fields that belong to page 3 (select data permissions)
export const SELECT_DATA_PERMISSIONS_FORM_FIELDS = [
  FORM_FIELD.DATA_ADMINS,
  FORM_FIELD.DATA_TEAM_MEMBERS,
];

export const SOURCE_TYPE_S3 = 'S3';
export const SOURCE_TYPE_HIVE = 'HIVE';

export const S3_FILE_FORMATS = [
  { value: FileFormat.JSON, label: 'JSON' },
  { value: FileFormat.PARQUET, label: 'Parquet' },
  { value: FileFormat.DSV_NOQUOTE, label: 'DSV' },
  { value: FileFormat.DSV_QUOTE, label: 'DSV (Quoted)' },
];
export const S3_DEFAULT_DELIMITER = '|';

export const HIVE_DEFAULT_VALUE = 'hive_metastore.dw.';

export const SOURCE_OPTION_HIVE = { value: SOURCE_TYPE_HIVE, label: 'Select data from warehouse table' };
export const SOURCE_OPTION_S3 = { value: SOURCE_TYPE_S3, label: 'Upload via S3' };
export const SOURCE_OPTIONS = [
  SOURCE_OPTION_HIVE,SOURCE_OPTION_S3,
];

export const DESTINATION_OPTIONS = [
  {
    value: 'HVM',
    label: 'HealthVerity marketplace (private data)',
  },
];

export const MONTH_OPTIONS = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
];

export const EXPIRATION_YEAR_OPTIONS = Array.from(
  { length: 5 },
  (_, num) => num + CURRENT_FULL_YEAR
).map((year) => ({ value: year, label: year }));

export const AVAILABLE_YEAR_OPTIONS = Array.from(
  { length: 125 },
  (_, num) => MAX_AVAILABLE_YEAR - num
).map((year) => ({ value: year, label: year }));

export const REFRESH_FREQUENCY_OPTIONS = [
  { value: 'NONE', label: 'None' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
];

export const DATA_MAPPING_DATA_TYPE = {
  HVID: 'HVID',
  NPI: 'NPI',
  BOTH: 'BOTH_HVID_AND_NPI',
};

export const DATA_TYPES = [
  { id: 'BIOSPECIMEN', name: 'Biospecimen' },
  { id: 'REGISTRY', name: 'Registry'},
  { id: 'CONSUMER', name: 'Consumer' },
  { id: 'EMR', name: 'EMR' },
  { id: 'HOSPITAL', name: 'Hospital' },
  { id: 'IMAGING', name: 'Imaging' },
  { id: 'LAB', name: 'Lab' },
  { id: 'MEDICALCLAIMS', name: 'Medical' },
  { id: 'PRIVATEDATA', name: 'Private Data' },
  { id: 'RXCLAIMS', name: 'Pharmacy' },
];

/**
 * Returns a list of formik values for the
 * first page (configure data flow)
 * Note: Additional checks will be performed in ConfigureDataFlow.tsx to ensure dataSourceS3 or dataSourceHive valid
 */
export function configureDataFlowValues(values: FormikValues) {
  return [values.dataSourceType, values.dataDestination, values.refreshFrequency];
}

/**
 * Returns a list of formik values for the
 * second page (provide more information).
 * The radio input is used to determine if
 * HVID or NPI or BOTH should be required.
 */
export function provideMoreInformationValues(values: FormikValues) {
  let requiredValues = [
    values.dataProvider,
    values.dataType,
    values.dataName,
    values.dataDescription,
  ];
  return requiredValues;
}

/**
 * Returns a list of formik values for the
 * third page (select data permissions).
 */
export function selectDataPermissionsValues(values: FormikValues) {
  return [values.dataAdmins, values.dataTeamMembers];
}

/**
 * Returns a list of possible columns index
 */
export const DATA_INDEX_COLUMNS: { [key: string]: { title: string; initial: string } } = {
  patientIdentifierField: {
    title: 'Patient ID',
    initial: 'hvid',
  },
  providerIdentifierField: {
    title: 'Provider ID',
    initial: 'npi',
  },
  ageField: {
    title: 'Patient age',
    initial: 'patient_age',
  },
  yearOfBirthField: {
    title: 'Patient year of birth',
    initial: 'patient_year_of_birth',
  },
  genderField: {
    title: 'Patient gender',
    initial: 'patient_gender',
  },
  bmiField: {
    title: 'Patient BMI',
    initial: 'vit_sign_typ_cd',
  },
  bmiMeasurementField: {
    title: 'BMI measurement',
    initial: 'vit_sign_msrmt',
  },
  patientStateField: {
    title: 'Patient state',
    initial: 'patient_state',
  },
  patientZip3Field: {
    title: 'Patient 3 digit zip',
    initial: 'patient_zip3',
  },
  providerStateField: {
    title: 'Provider state',
    initial: 'provider_state',
  },
  providerZip3Field: {
    title: 'Provider zip',
    initial: 'provider_zip',
  },
  providerSpecialtyField: {
    title: 'Provider specialty',
    initial: 'provider_specialty',
  },
  procedureField: {
    title: 'Procedure code',
    initial: 'procedure_code',
  },
  labField: {
    title: 'Lab code',
    initial: 'loinc_code',
  },
  drugField: {
    title: 'Drug code',
    initial: 'ndc_code',
  },
  diagnosisField: {
    title: 'Diagnosis code',
    initial: 'diagnosis_code',
  },
  diagnosisQualField: {
    title: 'Diagnosis code qualifier',
    initial: 'diagnosis_code_qual',
  },
  genericMedicationField: {
    title: 'Generic medication',
    initial: 'generic_medication',
  },
  dateFields: {
    title: 'Service date',
    initial: 'date_service',
  },
};

export const DEFAULT_FIELD_MAPPINGS: FieldMappingType = Object.entries(DATA_INDEX_COLUMNS).reduce(
  (acc, [field]) => ({
    ...acc,
    [field]: '',
  }),
  {}
);

export const DEFAULT_DATASET: Dataset = {
  name: '',
  description: '',
  datatype: '',
  data_provider: 0,
  ingest_date: '',
  is_public: false,
  status: 'active',
};

/*
 * Default Dataset Attribute Statement Generation config
 */
export const DEFAULT_DASG_CONFIG: DataFeedConfig = {
  id: '0',
  name: '',
  models: [],
  entities: [],
  earliestDate: '',
  refreshConfig: undefined,
  ignoreStartDate: true,
  ignoreEndDate: true,
  includeNullDates: true,
  hllConfig: undefined,
  dataLag: undefined,
};
