import React from 'react';

import './ConfigureDataFlow.scss';
import './DatasetForm.scss';
import '../App.scss';
import DataSourceInformation from './ProvideSourceInformation';
import { FileFormat } from '../../backend/proto/dataset_config';

import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import Select from 'react-select';
import {
  SOURCE_OPTIONS,
  DESTINATION_OPTIONS,
  FORM_FIELD,
  MONTH_OPTIONS,
  REFRESH_FREQUENCY_OPTIONS,
  EXPIRATION_YEAR_OPTIONS,
  configureDataFlowValues,
  SOURCE_TYPE_S3,
  SOURCE_TYPE_HIVE,
} from '../../constants/add-data-constants';

type ConfigureDataFlowProps = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: (name: string, value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  onNext: (event: React.MouseEvent) => void;
  scanDataSourceAndPerformMapping: (values: FormikValues) => void;
  validationIsLoading: boolean;
};

/**
 * Component to handle the page-1 "Configure Data Flow" part
 * of the Add new data workflow process
 */
export default function ConfigureDataFlow({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onNext,
  scanDataSourceAndPerformMapping,
  validationIsLoading,
}: ConfigureDataFlowProps) {
  const isNextButtonDisabled = () => {
    const hasMissingNonSourceValues = !configureDataFlowValues(values).every(Boolean);
    const hasSourceFieldErrors = !!errors.dataSourceS3 || !!errors.dataSourceHive;
    const hasHiveErrors =
      !!errors.dataSourceHiveConditions &&
      Object.values(errors.dataSourceHiveConditions).some(Boolean);
    const hasS3ValueErrors =
      values.dataSourceType.value == SOURCE_TYPE_S3 &&
      (!values.dataSourceS3.length || !touched.dataSourceS3);
    const hasHiveValueErrors =
      values.dataSourceType.value == SOURCE_TYPE_HIVE &&
      (!values.dataSourceHive.length || !touched.dataSourceHive);
    const isDelimitedType =
      values.dataSourceS3FileFormat.value === FileFormat.DSV_QUOTE ||
      values.dataSourceS3FileFormat.value === FileFormat.DSV_NOQUOTE;
    const hasS3DelimiterError = isDelimitedType && !!errors.dataSourceS3Delimiter;

    return (
      hasMissingNonSourceValues ||
      hasSourceFieldErrors ||
      hasHiveErrors ||
      hasS3ValueErrors ||
      hasHiveValueErrors ||
      hasS3DelimiterError
    );
  };

  const updateAutoIndexFieldsThenNext = async (event: React.MouseEvent) => {
    /** If the source data has changed, update the field mappings **/
    const checkIfSourceValueHasChanged = async () => {
      if (touched.dataSourceType || touched.dataSourceS3 || touched.dataSourceHive) {
        await scanDataSourceAndPerformMapping(values);
      }
    };
    await checkIfSourceValueHasChanged();
    onNext(event);
  };

  return (
    <div className={'configure-data-flow-data-source-container'}>
      <div className="info-box data-flow-source-section">
        Please indicate the source and destination of the data.
      </div>
      <div>
        <div className={'data-source-section-form'}>
          <label>Data source file path / directory</label>
          <div className={'data-destination-dropdown'}>
            <Select
              options={SOURCE_OPTIONS}
              name={FORM_FIELD.DATA_SOURCE_TYPE}
              value={values.dataSourceType}
              className={'hv-dropdown-container'}
              classNamePrefix={'hv-dropdown'}
              isSearchable={false}
              onChange={(chosenOption: any) =>
                handleChange(FORM_FIELD.DATA_SOURCE_TYPE, chosenOption)
              }
            />
          </div>
          {errors.dataSourceType && touched.dataSourceType && (
            <div className={'form-validation-error'}>{errors.dataSourceType}</div>
          )}
        </div>
        {values.dataSourceType && (
          <DataSourceInformation
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isLoading={validationIsLoading}
          />
        )}
        <div className={'data-destination-section-form'}>
          <label>Data destination</label>
          <div className={'data-destination-dropdown'}>
            <Select
              options={DESTINATION_OPTIONS}
              name={FORM_FIELD.DATA_DESTINATION}
              value={values.dataDestination}
              className={'hv-dropdown-container'}
              classNamePrefix={'hv-dropdown'}
              isSearchable={false}
            />
          </div>
        </div>
        <div className={'data-destination-section-form'}>
          <label>Select refresh frequency</label>
          <div className={'data-dropdown-row'}>
            <div className={'data-individual-dropdown'}>
              <Select
                options={REFRESH_FREQUENCY_OPTIONS}
                name={FORM_FIELD.REFRESH_FREQUENCY}
                value={values.refreshFrequency}
                className={'hv-dropdown-container'}
                classNamePrefix={'hv-dropdown'}
                isSearchable={false}
                placeholder={'Select refresh'}
                onChange={(chosenOption: any) =>
                  handleChange(FORM_FIELD.REFRESH_FREQUENCY, chosenOption)
                }
              />
            </div>
          </div>
        </div>
        {values.refreshFrequency && values.refreshFrequency.value !== 'NONE' && (
          <div className={'data-destination-section-form'}>
            <label>Expiration date</label>
            <div className={'data-dropdown-row'}>
              <div className={'data-individual-dropdown'}>
                <Select
                  options={MONTH_OPTIONS}
                  name={FORM_FIELD.EXPIRATION_MONTH}
                  value={values.expirationMonth}
                  className={'hv-dropdown-container'}
                  classNamePrefix={'hv-dropdown'}
                  isSearchable={false}
                  placeholder={'Select month'}
                  onChange={(chosenOption: any) =>
                    handleChange(FORM_FIELD.EXPIRATION_MONTH, chosenOption)
                  }
                />
              </div>
              <div className={'data-individual-dropdown'}>
                <Select
                  options={EXPIRATION_YEAR_OPTIONS}
                  name={FORM_FIELD.EXPIRATION_YEAR}
                  value={values.expirationYear}
                  className={'hv-dropdown-container'}
                  classNamePrefix={'hv-dropdown'}
                  isSearchable={false}
                  placeholder={'Select year'}
                  onChange={(chosenOption: any) =>
                    handleChange(FORM_FIELD.EXPIRATION_YEAR, chosenOption)
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className={'add-new-data-footer footer-page-1'}>
          <input
            type="button"
            value={'Next'}
            disabled={isNextButtonDisabled()}
            className={'generic-confirm-btn'}
            onClick={updateAutoIndexFieldsThenNext}
          />
        </div>
      </div>
    </div>
  );
}
