import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import Select from 'react-select';

import './ProvideDataInformation.scss';
import './DatasetForm.scss';
import '../App.scss';

import {
  DATA_MAPPING_DATA_TYPE,
  DATA_TYPES,
  FORM_FIELD,
  MONTH_OPTIONS,
  AVAILABLE_YEAR_OPTIONS,
  DATA_INDEX_COLUMNS,
  provideMoreInformationValues,
} from '../../constants/add-data-constants';
import { IBackend } from '../../backend';
import { DataProvider, FieldMappingType } from '../../types';
import ToggleSwitch from './ToggleSwitch';
import performFieldMappingOnScannedFields, {
  ScannedFieldMappingResultProps,
} from '../../utils/scannedFieldMapping';

type dataColumnOptionsType = {
  [key: string]: [string, string];
};

type ProvideDataInformationProps = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: (name: string, value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  onBack: () => void;
  onNext: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  dataProviders: DataProvider[];
  scannedFieldMappingResult: ScannedFieldMappingResultProps;
};

/**
 * Component that handles page-2 "Provide Data Information" part
 * of the add data workflow asking for more information about the dataset
 */
export default function ProvideDataInformation({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onBack,
  onNext,
  setFieldValue,
  dataProviders,
  scannedFieldMappingResult,
}: ProvideDataInformationProps) {
  const {
    scannedSourceFieldNames,
    bestResultAutoMappedFields,
    valuePreservingAutoMappedFields,
    autoMappedIndexedFields,
  } = scannedFieldMappingResult;

  /* Define field mapping dropdown options */
  const allSourceFieldsForDropdown = scannedSourceFieldNames.map((optionField) => ({
    value: optionField,
    label: optionField,
  }));

  const setFieldName = (category: string, name: string) => {
    if (name === '') {
      // also de-index
      onToggleChange(false, category);
    } else {
      onToggleChange(true, category);
    }
    setFieldValue('fieldMapping', { ...values.fieldMapping, [category]: name });
  };

  // Adds index category to formik values if toggle is active and removes if toggle is made inactive
  const onToggleChange = (active: boolean, field: string) => {
    const indexedFields = new Set(values.indexedFields);
    if (active) {
      indexedFields.add(field);
    } else {
      indexedFields.delete(field);
    }
    setFieldValue('indexedFields', indexedFields);
  };

  const getFieldOptionValue = (field: string) => {
    if (field in values.fieldMapping && values.fieldMapping[field].length) {
      return values.fieldMapping[field];
    }
    return null;
  };

  const getFieldOptionValueForDropdown = (fieldOptionValue: string) => {
    if (fieldOptionValue) {
      return { value: fieldOptionValue, label: fieldOptionValue };
    }
    return null;
  };

  const isAutodetectedField = (fieldOptionValue: string, field: string) => {
    return fieldOptionValue && fieldOptionValue === bestResultAutoMappedFields[field];
  };

  const renderDataIndexRows = Object.keys(values.fieldMapping).map((field) => {
    const fieldOptionValue = getFieldOptionValue(field);

    return (
      <tr key={field}>
        <td>{DATA_INDEX_COLUMNS[field].title}</td>
        <td style={{ padding: '0 10px' }}>
          <div className={'data-index-table-input-container'}>
            <Select
              isClearable={true}
              options={allSourceFieldsForDropdown}
              value={getFieldOptionValueForDropdown(fieldOptionValue)}
              name={field}
              className={'hv-dropdown-container'}
              classNamePrefix={'hv-dropdown'}
              isSearchable={false}
              placeholder={'Select'}
              onChange={(chosenOption: any) =>
                setFieldName(field, chosenOption?.value ? chosenOption.value : '')
              }
            />
            {isAutodetectedField(fieldOptionValue, field) && (
              <p className="autodetected">Auto-detected</p>
            )}
          </div>
        </td>
        <td>
          <div className={'data-index-toggle-container'}>
            {fieldOptionValue && (
              <ToggleSwitch
                setActive={(active) => onToggleChange(active, field)}
                active={values.indexedFields.has(field)}
              />
            )}
          </div>
        </td>
      </tr>
    );
  });

  /*
   * Disable if all values are not filled.
   * PatientIdentifierField or providerIdentifierField must be defined and not empty
   */
  const isNextButtonDisabled = () => {
    const hasIncorrectFields = !provideMoreInformationValues(values).every(Boolean);

    const missingRequiredIndexedFields = !(
      values.indexedFields.has('patientIdentifierField') ||
      values.indexedFields.has('providerIdentifierField')
    );

    const hasEmptyIndexedField = [...values.indexedFields].some(
      (field) => values.fieldMapping[field] === ''
    );

    const hasValidHistory = !!values.dataAvailableMonth == !!values.dataAvailableYear;

    return (
      hasIncorrectFields || missingRequiredIndexedFields || hasEmptyIndexedField || !hasValidHistory
    );
  };

  return (
    <div className={'provide-data-information-container'}>
      <div className={'data-mapping-section'}>
        <div className={'data-mapping-form'}>
          <h1>Part 1 - Data indexing</h1>
          <div className={'info-box'}>
            HealthVerity Marketplace allows indexing on the following categories, please select the
            corresponding field or column name from your data. Please check that any fields or
            columns that have been auto-detected are correct.{' '}
          </div>
          <div className={'data-index-table-container'}>
            <table className={'data-index-table'}>
              <thead>
                <tr className={'data-index-table-headers'}>
                  <th>Possible index categories</th>
                  <th style={{ width: '480px' }}>Field / column name</th>
                  <th>Index this field / column</th>
                </tr>
              </thead>
              <tbody>
                {!!scannedSourceFieldNames.length ? (
                  renderDataIndexRows
                ) : (
                  <tr key="emptyScannedSourceFieldNames">
                    <td colSpan={3}>
                      No fields were returned when scanning your data source. Please ensure your
                      source data is correct and try again.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={'data-description-section'}>
        <h1>Part 2 - Data description</h1>
        <div className={'info-box'}>
          Please provide important details about your data, this information will be displayed in
          HealthVerity Marketplace.
        </div>
        <div className={'data-description-form'}>
          <label> Data provider </label>
          <div className={'data-description-input-field'}>
            <Select
              options={dataProviders}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              name={FORM_FIELD.DATA_PROVIDER}
              value={values.dataProvider}
              className={'hv-dropdown-container'}
              classNamePrefix={'hv-dropdown'}
              isSearchable={true}
              placeholder={'Select'}
              onChange={(chosenOption: any) => handleChange(FORM_FIELD.DATA_PROVIDER, chosenOption)}
              isLoading={dataProviders.length === 0}
            />
          </div>

          <label> Data type </label>
          <div className={'data-description-input-field'}>
            <Select
              options={DATA_TYPES}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              name={FORM_FIELD.DATA_TYPE}
              value={values.dataType}
              className={'hv-dropdown-container'}
              classNamePrefix={'hv-dropdown'}
              isSearchable={false}
              placeholder={'Select'}
              onChange={(chosenOption: SyntheticEvent) =>
                handleChange(FORM_FIELD.DATA_TYPE, chosenOption)
              }
            />
          </div>

          <div className={'label-with-additional-margin label-with-additional-text'}>
            <label>Name</label>
            <span>(35 character limit)</span>
          </div>
          <input
            type="text"
            name={FORM_FIELD.DATA_NAME}
            value={values.dataName}
            onChange={(enteredText: SyntheticEvent) =>
              handleChange(FORM_FIELD.DATA_NAME, enteredText)
            }
            onBlur={handleBlur}
            placeholder={'Enter name'}
            className={'input-field input-states'}
          />

          <label>Data description</label>
          <textarea
            name={FORM_FIELD.DESCRIPTION}
            value={values.dataDescription}
            onChange={(enteredText: SyntheticEvent) =>
              handleChange(FORM_FIELD.DESCRIPTION, enteredText)
            }
            onBlur={handleBlur}
            className={'description-box input-states'}
            placeholder={'Describe the dataset'}
          />

          <label>Available history</label>
          <div className={'data-dropdown-row'}>
            <div className={'data-individual-dropdown'}>
              <Select
                options={MONTH_OPTIONS}
                name={FORM_FIELD.AVAILABLE_MONTH}
                value={values.dataAvailableMonth}
                className={'hv-dropdown-container'}
                classNamePrefix={'hv-dropdown'}
                isSearchable={false}
                placeholder={'Select month'}
                onChange={(chosenOption: any) =>
                  handleChange(FORM_FIELD.AVAILABLE_MONTH, chosenOption)
                }
              />
            </div>
            <div className={'data-individual-dropdown'}>
              <Select
                options={AVAILABLE_YEAR_OPTIONS}
                name={FORM_FIELD.AVAILABLE_YEAR}
                value={values.dataAvailableYear}
                className={'hv-dropdown-container'}
                classNamePrefix={'hv-dropdown'}
                isSearchable={true}
                placeholder={'Select year'}
                onChange={(chosenOption: any) =>
                  handleChange(FORM_FIELD.AVAILABLE_YEAR, chosenOption)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'add-new-data-footer'}>
        <input
          type="button"
          value={'Back'}
          disabled={false}
          className={'generic-dismiss-btn'}
          onClick={() => onBack()}
        />
        <input
          type="button"
          value={'Next'}
          disabled={isNextButtonDisabled()}
          className={'generic-confirm-btn'}
          onClick={() => onNext()}
        />
      </div>
    </div>
  );
}
