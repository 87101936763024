import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { REQUIRED_PERMS, ATTR_STMT_REQUIRED_PERMS } from './components/App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const AUTH0_AUDIENCE: string = process.env['REACT_APP_AUTH0_AUDIENCE'] || '';
const AUTH0_CLIENT_ID: string = process.env['REACT_APP_AUTH0_CLIENT_ID'] || '';
const AUTH0_DOMAIN: string = process.env['REACT_APP_AUTH0_DOMAIN'] || '';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      scope={REQUIRED_PERMS.concat(ATTR_STMT_REQUIRED_PERMS).join(' ')}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
