import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import CenterModal from './CenterModal';
import './CenterModal.scss';
import './RefreshHistoryModal.scss';

import { AttributeStatementBackend, FeedHistoryRequest } from '../../backend';
import { filterFeedHistoryRequests } from '../../backend/formatters';
import { Dataset, RefreshHistoryRequest } from '../../types';

type RefreshHistoryCardProps = {
  index: number;
  request: RefreshHistoryRequest;
};

function RefreshHistoryCardRow({ index, request }: RefreshHistoryCardProps) {
  const formatDatetime = (datetime: string) => {
    const date = new Date(datetime + 'Z');
    return (
      date.toLocaleString('default', { month: 'short', day: '2-digit', year: 'numeric' }) +
      ' - ' +
      date.toLocaleString('default', { hour: 'numeric', minute: '2-digit' })
    );
  };

  // Refresh #s begin at 0 but for display, start at 1
  const formatRefreshNumber = (refresh_number: number) => {
    return refresh_number + 1;
  };

  return (
    <div data-testid={`RefreshHistoryCardRow_${index}`} className="modal-item-card-container">
      <div className="modal-item-card">
        <div className="modal-item-card-row">
          <span>Refresh time:</span>
          <div>{formatDatetime(request.date)}</div>
        </div>
        <div className="modal-item-card-row">
          <span>Refresh type:</span>
          <div>{request.is_automatic === true ? 'Automatic' : 'Manual'}</div>
        </div>
        <div className="modal-item-card-row">
          <span>Refresh number:</span>
          <div>
            {request.is_automatic === true && request.refresh_number !== null
              ? formatRefreshNumber(request.refresh_number)
              : 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );
}

type RefreshHistoryModalProps = {
  dataset: Dataset;
  attrStmtBackend: AttributeStatementBackend;
  setRefreshHistoryModalDatafeed: Dispatch<SetStateAction<Dataset | null>>;
};

export default function RefreshHistoryModal({
  dataset,
  attrStmtBackend,
  setRefreshHistoryModalDatafeed,
}: RefreshHistoryModalProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshHistoryRequests, setRefreshHistoryRequests] = useState<RefreshHistoryRequest[]>([]);

  useEffect(() => {
    const loadDatasetRequestHistory = async (datasetID: number) => {
      const feedHistoryRequest: FeedHistoryRequest =
        await attrStmtBackend.getDataFeedRefreshHistory(datasetID);
      const filteredFeedHistoryRequest = filterFeedHistoryRequests(feedHistoryRequest.requests);
      setRefreshHistoryRequests(filteredFeedHistoryRequest);
      setIsLoading(false);
    };

    if (dataset && !!dataset.id) {
      loadDatasetRequestHistory(dataset.id);
    }
  }, [dataset]);

  // Main div that renders the contents of a page
  const modalRoot: any = document.getElementById('contents');
  // Create a domNode and add it to the modalRoot
  const domNode: any = document.createElement('div');
  modalRoot.appendChild(domNode);

  return ReactDOM.createPortal(
    <CenterModal show={true} close={() => setRefreshHistoryModalDatafeed(null)}>
      <div data-testid={`RefreshHistoryModal_${dataset.id}`} className="modal-container">
        <div className="modal-slim-header">
          <span>{dataset.name}</span>
        </div>
        <div className="modal-body">
          {isLoading && (
            <div className="modal-item-card">
              <div className="modal-item-card-row">
                <div title="refresh-loading" className="loading" />
              </div>
            </div>
          )}
          {!isLoading && !refreshHistoryRequests.length && (
            <div className="modal-item-card">
              <div className="modal-item-card-row">
                <div>No history to display.</div>
              </div>
            </div>
          )}
          {refreshHistoryRequests.map((request: RefreshHistoryRequest, index: number) => {
            return (
              <RefreshHistoryCardRow key={request.request_id} request={request} index={index} />
            );
          })}
        </div>
        <div className="modal-footer">
          <input
            type="button"
            value={'Close'}
            onClick={() => setRefreshHistoryModalDatafeed(null)}
            className={'generic-modal-dismiss-btn  modal-slim-footer-button'}
          />
        </div>
      </div>
    </CenterModal>,
    domNode
  );
}
