import { useEffect, useRef } from 'react';
import isEqual from 'lodash.isequal';

const useDeepCompareMemoize = (value: any) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

/*
 * Custom hook replaces useEffect when you want compare object{}
 * dependencies by value instead of memory reference. Useful for
 * when your useEffect needs to depend on state that is a complex
 * object.
 */
const useDeepCompareEffect = (callback: () => void, dependencies: any[]) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffect;
