import React, { useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { NameCell, StatusCell, LastRefreshCell } from './Rows';
import { Dataset } from '../../types';

import './Table.scss';

import { capitalize } from '../../utils/utilities';

const HEADERS: { [key: string]: string } = {
  Name: '200px',
  'Dataset ID': '80px',
  Destination: '110px',
  Refresh: '80px',
  'Last refresh': '140px',
  'Next refresh': '80px',
  Expiration: '80px',
  Status: '80px',
};

const REFRESH_MAP: { [key: string]: string } = {
  ONETIME: 'One time',
  MONTHLY: 'Monthly',
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
  QUARTERLY: 'Quarterly',
};

type TableProps = {
  datasets: Dataset[] | null;
  setTableRef: (ref: any) => void;
  loadNextPage: () => void;
  search?: string;
  onSelect: (dataset: Dataset, checked: boolean) => void;
  selectedDatasets: Set<string>;
  setRefreshHistoryModalDatafeed: Dispatch<SetStateAction<Dataset | null>>;
  isLoading: boolean;
};

/*
 * Renders one table using data passed in as props. Creates a ref so that
 * dynamic style, dom manipulation can occur. Will display a no results
 * component when data prop is an empty list.
 */
export default function Table({
  datasets,
  setTableRef,
  loadNextPage,
  search,
  onSelect,
  selectedDatasets,
  setRefreshHistoryModalDatafeed,
  isLoading,
}: TableProps) {
  const headers = HEADERS;
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const scrollEvent = e.target as HTMLDivElement;
    const bottom = scrollEvent.scrollHeight - scrollEvent.scrollTop === scrollEvent.clientHeight;
    if (bottom) {
      loadNextPage();
    }
  };
  return (
    <div className={'table-container'}>
      {datasets ? (
        datasets.length > 0 ? (
          <div ref={setTableRef} className={'table-ref'} onScroll={handleScroll}>
            <table className={'table'}>
              <thead>
                <tr className={'table-headers'}>
                  {Object.keys(headers).map((header) => (
                    <th key={header} className={'table-header'} style={{ width: headers[header] }}>
                      <span>{header}</span>
                    </th>
                  ))}
                </tr>
              </thead>
              {!isLoading && (
                <tbody>
                  {datasets.map((dataset: any, index: number) => {
                    return (
                      <Row
                        key={index}
                        index={index}
                        dataset={dataset}
                        onSelect={onSelect}
                        selected={selectedDatasets.has(dataset.id.toString())}
                        setRefreshHistoryModalDatafeed={() =>
                          setRefreshHistoryModalDatafeed(dataset)
                        }
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
            {isLoading && <div className="table-loading" />}
          </div>
        ) : (
          <NoResults search={search} />
        )
      ) : (
        <div className="table-loading" />
      )}
    </div>
  );
}

type RowProps = {
  index: number;
  dataset: Dataset;
  onSelect: (dataset: Dataset, checked: boolean) => void;
  selected: boolean;
  setRefreshHistoryModalDatafeed: (dataset: Dataset | null) => void;
};

/*
 * Renders an individual dataset row in the table.
 */
export function Row({
  index,
  dataset,
  onSelect,
  selected,
  setRefreshHistoryModalDatafeed,
}: RowProps) {
  const history = useHistory();

  return (
    <>
      <tr
        className={'table-row'}
        style={{ cursor: !dataset.is_public ? 'pointer' : 'auto' }}
        onClick={() => (!dataset.is_public ? history.push(`/edit-dataset/${dataset.id}`) : null)}
      >
        <td>
          <div className="table-first-row">
            <div className="table-first-row-checkbox">
              <input
                type="checkbox"
                checked={selected}
                onChange={(e) => {
                  e.stopPropagation();
                  onSelect(dataset, e.target.checked);
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <NameCell name={dataset.name} type={dataset.datatype} />
          </div>
        </td>
        <td>{dataset.id}</td>
        <td>{dataset.is_public ? 'Public Marketplace' : 'Private Marketplace'}</td>
        <td>
          {dataset.refresh?.refresh_config
            ? capitalize(dataset.refresh.refresh_config.interval)
            : 'None'}
        </td>
        <td>
          <LastRefreshCell
            dataset={dataset}
            setRefreshHistoryModalDatafeed={setRefreshHistoryModalDatafeed}
          />
        </td>
        <td>
          {dataset.refresh?.next_run ? formatDatetime(dataset.refresh?.next_run.date) : 'None'}
        </td>
        <td>-</td>
        <td>
          <StatusCell status={capitalize(dataset.status)} />
        </td>
      </tr>
    </>
  );
}

export function formatDatetime(datetime: string) {
  const date = new Date(datetime + 'Z');
  return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
}

type NoResultsProps = {
  search: string | undefined;
};

const NoResults = ({ search }: NoResultsProps) => {
  return !search ? (
    <div className="no-results">
      <span>There are no datasets to display in this section.</span>
    </div>
  ) : (
    <div className="no-search-results">
      <h2>No results found</h2>
      <p>
        Your search - <span>{search}</span> - did not return an results
      </p>
      <span>
        <span>Search by:</span>
        <ul>
          <li>Dataset Name</li>
          <li>Dataset ID</li>
        </ul>
      </span>
    </div>
  );
};
