import React from 'react';

import './Heading.scss';
import Search from './Search';

type HeadingProps = {
  submitSearch: () => void;
  search: string;
  setSearch: (search: string) => void;
};

export default function Heading({ submitSearch, search, setSearch }: HeadingProps) {
  return (
    <div className="table-heading">
      <h1>Data Management</h1>

      <Search setSearch={setSearch} submitSearch={submitSearch} search={search} />
    </div>
  );
}
