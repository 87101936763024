/**
 * Interface for authenticating requests
 */
export interface IAuthenticator {
  /**
   * A function that injects auth details into a provided request object
   */
  authenticateRequest: (request: Request) => Promise<void>;
}

/**
 * Authenticates requests by injecting a Authentication header with a Bearer
 * auth token
 */
export class Auth0Authenticator implements IAuthenticator {
  private getToken: () => Promise<string>;
  private token?: string;

  constructor(getToken: () => Promise<string>) {
    this.getToken = async () => {
      const token = this.token ? this.token : await getToken();
      this.token = token;
      return token;
    };
  }

  async authenticateRequest(request: Request) {
    request.headers.set('Authorization', `Bearer ${await this.getToken()}`);
  }
}
