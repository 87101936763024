/** Functionality to handle tightly coupled loading, return, and formatted values **/
import { FormikValues } from 'formik';
import { DataIndexColumns, FieldMappingType } from '../types';
import { DATA_INDEX_COLUMNS, DEFAULT_FIELD_MAPPINGS } from '../constants/add-data-constants';

const defaultFieldMappings = DEFAULT_FIELD_MAPPINGS;

export type ScannedFieldMappingResultProps = {
  /* Auto-detected scanned field names */
  scannedSourceFieldNames: string[];
  /* Processed field mapping WITHOUT accounting for previously selected values by matchAllScannedFields */
  bestResultAutoMappedFields: FieldMappingType;
  /* Field mapping as returned by the matchAllScannedFields function accounting for previously selected value */
  valuePreservingAutoMappedFields: FieldMappingType;
  /* Set of indexed field values based on keys of valuePreservingAutoMappedFields with values */
  autoMappedIndexedFields: Set<string>;
};

export const scannedFieldMappingEmptyResult = {
  scannedSourceFieldNames: [],
  bestResultAutoMappedFields: defaultFieldMappings,
  valuePreservingAutoMappedFields: defaultFieldMappings,
  autoMappedIndexedFields: new Set([]),
};

const performFieldMappingOnScannedFields = (
  scannedFieldNames: string[],
  currentlySelectedValues: FormikValues
): ScannedFieldMappingResultProps => {
  const bestResultAutoMappedFields = matchAllScannedFields(
    scannedFieldNames,
    currentlySelectedValues,
    false
  );
  const valuePreservingAutoMappedFields = matchAllScannedFields(
    scannedFieldNames,
    currentlySelectedValues,
    true
  );
  const autoMappedIndexedFields = new Set(
    Object.keys(valuePreservingAutoMappedFields).filter(
      (fieldKey) => valuePreservingAutoMappedFields[fieldKey].length > 0
    )
  );

  return {
    scannedSourceFieldNames: scannedFieldNames,
    bestResultAutoMappedFields: bestResultAutoMappedFields,
    valuePreservingAutoMappedFields: valuePreservingAutoMappedFields,
    autoMappedIndexedFields: autoMappedIndexedFields,
  };
};

export default performFieldMappingOnScannedFields;

const matchAllScannedFields = (
  scannedFieldNames: string[],
  previousFieldMappings: FieldMappingType,
  preservePreviousFieldMappings: boolean
): FieldMappingType => {
  const fieldMatcher = (
    fieldName: string,
    scannedFieldNames: string[],
    previousFieldMappings: FieldMappingType
  ) => {
    const { initial } = DATA_INDEX_COLUMNS[fieldName];

    /* 1st Check: Field has an entry in previousFieldMappings that is also in scannedSourceFieldNames */
    const matchesPrevious = (scannedFieldName: string) => {
      if (fieldName in previousFieldMappings) {
        const previousFieldMapping = previousFieldMappings[fieldName];
        if (
          previousFieldMapping &&
          previousFieldMapping.length &&
          previousFieldMapping === scannedFieldName
        ) {
          return true;
        }
      }
      return false;
    };
    if (preservePreviousFieldMappings) {
      const matchedValuePrevious = scannedFieldNames.filter((scannedFieldName) =>
        matchesPrevious(scannedFieldName)
      );
      if (matchedValuePrevious.length) {
        return matchedValuePrevious[0];
      }
    }

    /* 2nd Check: Field has a scannedSourceFieldNames that matches an initial value */
    const matchesInitial = (scannedFieldName: string) => {
      return scannedFieldName === initial;
    };

    const matchedValueInitial = scannedFieldNames.filter((scannedFieldName) =>
      matchesInitial(scannedFieldName)
    );
    if (matchedValueInitial.length) {
      return matchedValueInitial[0];
    }

    return '';
  };

  return Object.entries(DATA_INDEX_COLUMNS).reduce(
    (acc, [field]) => ({
      ...acc,
      [field]: fieldMatcher(field, scannedFieldNames, previousFieldMappings),
    }),
    DEFAULT_FIELD_MAPPINGS
  );
};
