import React from 'react';
import { Link } from 'react-router-dom';
import './ProcessingNotification.scss';
import '../App.scss';

export default function ProcessingNotification() {
  return (
    <div className={'processing-container'}>
      <div className={'processing-header'}>Dataset processing...</div>
      <div className={'processing-message'}>
        Your dataset is being processed now, we will notify you via email as soon as it is ready for
        you to review in HealthVerity Marketplace. Data processing typically takes 24-48 hours, for
        more information contact
        <span> </span>
        <a href="mailto: support@healthverity.com">support@healthverity.com</a>.
      </div>
      <div className={'processing-footer'}>
        <Link to="/">
          <button type="button" className={'processing-button'}>
            Close
          </button>
        </Link>
      </div>
    </div>
  );
}
