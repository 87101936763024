import { FormikValues, FormikErrors } from 'formik';
import './NewDataSummary.scss';
import '../App.scss';
import React from 'react';
import {
  FORM_FIELD,
  SOURCE_TYPE_S3,
  SOURCE_TYPE_HIVE,
  DATA_INDEX_COLUMNS,
} from '../../constants/add-data-constants';
import { FileFormat } from '../../backend/proto/dataset_config';
import makePending from '../../images/make-pending.svg';


type NewDataSummaryType = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  onBack: () => void;
  onNext: () => void;
  onEdit: (event: React.MouseEvent, field: string) => void;
  isSubmitting: boolean;
};

/**
 * Component to render the table that summarizes
 * all the data user has chosen. Each field has an
 * option to edit which takes the user back to
 * the corresponding page to allow for changes
 *
 */
export default function NewDataSummary({
  values,
  errors,
  onBack,
  onNext,
  onEdit,
  isSubmitting,
}: NewDataSummaryType) {


  const isSubmitDisabled = () => {

    if (isSubmitting) {
      return true;
    }

    if (values.dataSourceType.value === SOURCE_TYPE_S3) {
      return true;
    }

    return false;
  }

  return (
    <div className={'select-data-permissions-container'}>
      <div className={'new-data-summary-section'}>
        <div className={'info-box'}>
          Below you will see a summary of your data. Please review and edit, as needed. Clicking the
          “Add data to HealthVerity Marketplace” will initiate the processing of your data request.
          You will receive an email when the data is ready for review before publication to
          HealthVerity Marketplace.
        </div>

        {/* DEPRECATION OF S3 FILE SOURCES! */}
        {values.dataSourceType.value === SOURCE_TYPE_S3 && (
          <div className="file-deprecation-warning">
            <h3><img src={makePending} className="option-icon" /> File source deprecation notice!</h3>
            <p>
              Please stage the desired data in a publicly available warehouse location and 
              use the "Select data from warehouse table" option!
            </p>
          </div>
        )}


        <div className={'new-data-summary-table-container'}>
          <table className={'new-data-summary-table'}>
            <colgroup>
              <col span={1} className={'summary-table-label-column'} />
              <col span={1} className={'summary-table-value-column'} />
              <col span={1} className={'summary-table-edit-column'} />
            </colgroup>
            <tbody>
              <tr>
                <td className={'summary-table-label-cell'}>Data provider name</td>
                <td className={'summary-table-value-cell'}>{values.dataProvider.name}</td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DATA_PROVIDER)}
                >
                  Edit
                </td>
              </tr>
              {values.dataSourceType.value === SOURCE_TYPE_S3 && (
                <>
                  <tr>
                    <td className={'summary-table-label-cell'}><img src={makePending} className="option-icon" /> File path / directory</td>
                    <td className={'summary-table-value-cell'}>
                      {values.dataSourceS3}
                      {errors.dataSourceS3 && (
                        <span className={'summary-table-error-span'}>{errors.dataSourceS3}</span>
                      )}
                    </td>
                    <td
                      className={'summary-table-edit-cell'}
                      onClick={(e) => onEdit(e, FORM_FIELD.DATA_SOURCE_S3)}
                    >
                      Edit
                    </td>
                  </tr>
                  <tr>
                    <td className={'summary-table-label-cell'}><img src={makePending} className="option-icon" /> File format</td>
                    <td className={'summary-table-value-cell'}>
                      {values.dataSourceS3FileFormat.label}
                    </td>
                    <td
                      className={'summary-table-edit-cell'}
                      onClick={(e) => onEdit(e, FORM_FIELD.DATA_SOURCE_S3_FILEFORMAT)}
                    >
                      Edit
                    </td>
                  </tr>
                  {[FileFormat.DSV_NOQUOTE, FileFormat.DSV_QUOTE].includes(
                    values.dataSourceS3FileFormat.value
                  ) && (
                    <tr>
                      <td className={'summary-table-label-cell'}><img src={makePending} className="option-icon" />  Delimiter</td>
                      <td className={'summary-table-value-cell'}>
                        {values.dataSourceS3Delimiter}
                        {errors.dataSourceS3Delimiter && (
                          <span className={'summary-table-error-span'}>
                            {errors.dataSourceS3Delimiter}
                          </span>
                        )}
                      </td>
                      <td
                        className={'summary-table-edit-cell'}
                        onClick={(e) => onEdit(e, FORM_FIELD.DATA_SOURCE_S3_DELIMITER)}
                      >
                        Edit
                      </td>
                    </tr>
                  )}
                </>
              )}
              {values.dataSourceType.value === SOURCE_TYPE_HIVE && (
                <tr>
                  <td className={'summary-table-label-cell'}>Warehouse table name</td>
                  <td className={'summary-table-value-cell'}>
                    {values.dataSourceHive}
                    {errors.dataSourceHive && (
                      <span className={'summary-table-error-span'}>{errors.dataSourceHive}</span>
                    )}
                  </td>
                  <td
                    className={'summary-table-edit-cell'}
                    onClick={(e) => onEdit(e, FORM_FIELD.DATA_SOURCE_HIVE)}
                  >
                    Edit
                  </td>
                </tr>
              )}
              <tr>
                <td className={'summary-table-label-cell'}>Destination</td>
                <td className={'summary-table-value-cell'}>{values.dataDestination.label}</td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DATA_DESTINATION)}
                >
                  Edit
                </td>
              </tr>
              <tr>
                <td className={'summary-table-label-cell'}>Refresh rate</td>
                <td className={'summary-table-value-cell'}>{values.refreshFrequency.label}</td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.REFRESH_FREQUENCY)}
                >
                  Edit
                </td>
              </tr>
              {values.refreshFrequency.value !== 'NONE' &&
                values.expirationMonth &&
                values.expirationYear && (
                  <tr>
                    <td className={'summary-table-label-cell'}>Expiration date</td>
                    <td className={'summary-table-value-cell'}>
                      {values.expirationMonth.label} {values.expirationYear.label}
                    </td>
                    <td
                      className={'summary-table-edit-cell'}
                      onClick={(e) => onEdit(e, FORM_FIELD.EXPIRATION_MONTH)}
                    >
                      Edit
                    </td>
                  </tr>
                )}
              {[...values.indexedFields].map((field) => (
                <tr key={field}>
                  <td className={'summary-table-label-cell'}>
                    {DATA_INDEX_COLUMNS[field].title} field name
                  </td>
                  <td className={'summary-table-value-cell'}>{values.fieldMapping[field]}</td>
                  <td
                    className={'summary-table-edit-cell'}
                    onClick={(e) => onEdit(e, FORM_FIELD.FIELD_MAPPING)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
              <tr>
                <td className={'summary-table-label-cell'}>Data type</td>
                <td className={'summary-table-value-cell'}>{values.dataType.name}</td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DATA_TYPE)}
                >
                  Edit
                </td>
              </tr>
              <tr>
                <td className={'summary-table-label-cell'}>Name</td>
                <td className={'summary-table-value-cell'}>{values.dataName}</td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DATA_NAME)}
                >
                  Edit
                </td>
              </tr>
              <tr>
                <td className={'summary-table-label-cell'}>Data description</td>
                <td className={'summary-table-value-cell'}>{values.dataDescription}</td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DESCRIPTION)}
                >
                  Edit
                </td>
              </tr>
              {values.dataAvailableMonth != undefined && values.dataAvailableYear != undefined && (
                <tr>
                  <td className={'summary-table-label-cell'}>Available history</td>
                  <td className={'summary-table-value-cell'}>
                    {values.dataAvailableMonth?.label} {values.dataAvailableYear?.label}
                  </td>
                  <td
                    className={'summary-table-edit-cell'}
                    onClick={(e) => onEdit(e, FORM_FIELD.AVAILABLE_MONTH)}
                  >
                    Edit
                  </td>
                </tr>
              )}
              <tr>
                <td className={'summary-table-label-cell'}>Data administrators</td>
                <td className={'summary-table-value-cell name-email-display-box'}>
                  {values.dataAdmins.length > 0
                    ? values.dataAdmins.map(
                        (dataAdmin: {
                          user_id: number;
                          last_name: string;
                          first_name: string;
                          email: string;
                        }) => (
                          <div
                            key={dataAdmin.user_id}
                            style={{ display: 'flex', height: '25px', alignItems: 'center' }}
                          >
                            {dataAdmin.last_name && dataAdmin.first_name && (
                              <div style={{ width: '150px', marginRight: '30px' }}>
                                {dataAdmin.last_name + ', ' + dataAdmin.first_name}
                              </div>
                            )}
                            <div>{dataAdmin.email}</div>
                          </div>
                        )
                      )
                    : 'None'}
                  {errors.dataAdmins && (
                    <span className={'summary-table-error-span'}>{errors.dataAdmins}</span>
                  )}
                </td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DATA_ADMINS)}
                >
                  Edit
                </td>
              </tr>
              <tr>
                <td className={'summary-table-label-cell'}>Team members</td>
                <td className={'summary-table-value-cell name-email-display-box'}>
                  {values.dataAdmins.length > 0
                    ? values.dataTeamMembers.map(
                        (dataTeamMember: {
                          user_id: number;
                          last_name: string;
                          first_name: string;
                          email: string;
                        }) => (
                          <div
                            key={dataTeamMember.user_id}
                            style={{ display: 'flex', height: '25px', alignItems: 'center' }}
                          >
                            {dataTeamMember.last_name && dataTeamMember.first_name && (
                              <div style={{ width: '150px', marginRight: '30px' }}>
                                {dataTeamMember.last_name + ', ' + dataTeamMember.first_name}
                              </div>
                            )}
                            <div>{dataTeamMember.email}</div>
                          </div>
                        )
                      )
                    : 'None'}
                  {errors.dataTeamMembers && (
                    <span className={'summary-table-error-span'}>{errors.dataTeamMembers}</span>
                  )}
                </td>
                <td
                  className={'summary-table-edit-cell'}
                  onClick={(e) => onEdit(e, FORM_FIELD.DATA_TEAM_MEMBERS)}
                >
                  Edit
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={'add-new-data-footer'}>
        <input
          type="button"
          value={'Back'}
          disabled={isSubmitting}
          className={'generic-dismiss-btn'}
          onClick={() => onBack()}
        />
        <input
          type="button"
          disabled={isSubmitDisabled()}
          value={'Submit'}
          className={'generic-confirm-btn'}
          onClick={() => onNext()}
        />
      </div>
    </div>
  );
}
