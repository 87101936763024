import React from 'react';

import './Navbar.css';
import logo from '../../images/logo.png';

type Props = {
  onLogout?: () => void;
  loggedIn?: boolean;
};

export default function Navbar({ onLogout, loggedIn = false }: Props) {
  return (
    <div className="nav-container">
      <div className="nav-left nav-section" />
      <div className="nav-center nav-section">
        <img src={logo} />
      </div>
      <div className={'nav-right nav-section'}>
        {loggedIn && (
          <a href="#" onClick={onLogout}>
            Log Out
          </a>
        )}
      </div>
    </div>
  );
}
