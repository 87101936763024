import GenericModal from './GenericModal';
import React, { useState } from 'react';
import './GenericModal.scss';
import './IdentifiableInfoModal.scss';

type IdentifiableInfoModalProps = {
  onCancel: () => void;
  onContinue: (isChecked: boolean) => void;
};

/**
 * Component that renders the IdentifiableInfoModal
 * that is asks the user to confirm that no identifiable
 * information will be shared in HVM.
 */
export default function IdentifiableInfoModal({
  onCancel,
  onContinue,
}: IdentifiableInfoModalProps) {
  const [isChecked, setIsChecked] = useState(false);

  /**
   * Toggles the checkbox
   */
  const handleCheckChanged = () => {
    setIsChecked(!isChecked);
  };

  const body = (
    <div>
      <div className="generic-modal-instructions-box">
        HealthVerity Marketplace has been de-identified pursuant to HIPAA and certified as such. No
        personally identifiable information (PII) or patient health information (PHI) can be shared
        in HealthVerity Marketplace.
      </div>
      <div className="identifiable-modal-checkbox">
        <input
          type="checkbox"
          id="identifiable-checkbox"
          checked={isChecked}
          onChange={handleCheckChanged}
        />
        <label onClick={handleCheckChanged}>
          I confirm that no identifiable information will be shared in HealthVerity Marketplace.
        </label>
      </div>
    </div>
  );

  return (
    <div>
      <GenericModal
        customModalClassName={'generic-wide-modal'}
        headerText={'Data must not contain identifiable information'}
        show={true}
        close={onCancel}
        onSubmit={(e) => {
          e.preventDefault();
          onContinue(isChecked);
        }}
        dismissButtonText={'Cancel'}
        confirmButtonText={'Continue'}
        customFooterClassName={'generic-modal-footer-border save-project-footer'}
        disableConfirm={!isChecked}
      >
        {body}
      </GenericModal>
    </div>
  );
}
