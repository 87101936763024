import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FormikErrors, FormikTouched, FormikValues, Field, FieldArray, ErrorMessage } from 'formik';
import makePending from '../../images/make-pending.svg';
import './ProvideSourceInformation.scss';
import './DatasetForm.scss';
import '../App.scss';
import { FileFormat } from '../../backend/proto/dataset_config';
import {
  FORM_FIELD,
  SOURCE_TYPE_S3,
  SOURCE_TYPE_HIVE,
  S3_FILE_FORMATS,
} from '../../constants/add-data-constants';
import { IBackend } from '../../backend';
import { DataProvider, HiveCondition } from '../../types';
import { stringify } from 'querystring';
import { ReactComponent as SvgFilter } from '../../images/filter.svg';
import { ReactComponent as SvgFilterRemove } from '../../images/filter_remove.svg';
import { ReactComponent as SvgValid } from '../../images/valid.svg';

type ProvideSourceInformationProps = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: (name: string, value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  isLoading: boolean;
};

/**
 * Component that handles page-1 "Provide Source Information" part
 * of the add data workflow
 */
export default function ProvideSourceInformation({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isLoading,
}: ProvideSourceInformationProps) {
  if (values.dataSourceType.value === SOURCE_TYPE_S3) {
    const isDelimitedType =
      values.dataSourceS3FileFormat.value === FileFormat.DSV_QUOTE ||
      values.dataSourceS3FileFormat.value === FileFormat.DSV_NOQUOTE;
    return (
      <>
        <div className={'data-source-section-form'}>

          {/* DEPRECATION OF S3 FILE SOURCES! */}
          <div className="file-deprecation-warning">
          <h3><img src={makePending} className="option-icon" /> File source deprecation notice!</h3>
          <p>
              Please stage the desired data in a publicly available warehouse location and 
              use the "Select data from warehouse table" option!
            </p>
          </div>

          <label>Data source directory</label>
          <div className="input-icon-row">
            <input
              type="text"
              name={FORM_FIELD.DATA_SOURCE_S3}
              value={values.dataSourceS3}
              onChange={(enteredText: any) => handleChange(FORM_FIELD.DATA_SOURCE_S3, enteredText)}
              onBlur={handleBlur}
              placeholder={'Enter a valid S3 URL'}
              className={'input-states'}
              disabled={true}
            />
            {isLoading ? (
              <div className="loading-icon" />
            ) : !errors.dataSourceS3 && touched.dataSourceS3 ? (
              <div className="valid-icon margin-left">
                <SvgValid />
              </div>
            ) : (
              <div />
            )}
          </div>
          {errors.dataSourceS3 && touched.dataSourceS3 && !isLoading && (
            <div className={'form-validation-error'}>{errors.dataSourceS3}</div>
          )}
        </div>
        <div className={'data-source-section-form'}>
          <label>File format</label>
          <div className={'data-destination-dropdown'}>
            <Select
              options={S3_FILE_FORMATS}
              name={FORM_FIELD.DATA_SOURCE_S3_FILEFORMAT}
              value={values.dataSourceS3FileFormat}
              className={'hv-dropdown-container'}
              classNamePrefix={'hv-dropdown'}
              isSearchable={false}
              onChange={(chosenOption: any) =>
                handleChange(FORM_FIELD.DATA_SOURCE_S3_FILEFORMAT, chosenOption)
              }
              isDisabled={true}
            />
          </div>
          {errors.dataSourceS3FileFormat && touched.dataSourceS3FileFormat && (
            <div className={'form-validation-error'}>{errors.dataSourceS3FileFormat}</div>
          )}
        </div>
        {isDelimitedType && (
          <div className={'data-source-section-form'}>
            <div className={'label-with-additional-text'}>
              <label>Delimiter</label>
              <span>(One or more characters that separate text strings)</span>
            </div>
            <div className="input-icon-row">
              <input
                type="text"
                name={FORM_FIELD.DATA_SOURCE_S3_DELIMITER}
                value={values.dataSourceS3Delimiter}
                onChange={(enteredText: any) =>
                  handleChange(FORM_FIELD.DATA_SOURCE_S3_DELIMITER, enteredText)
                }
                onBlur={handleBlur}
                placeholder={'Enter a valid delimiter'}
                className={'input-states'}
              />
            </div>
            {errors.dataSourceS3Delimiter && touched.dataSourceS3Delimiter && (
              <div className={'form-validation-error'}>{errors.dataSourceS3Delimiter}</div>
            )}
          </div>
        )}
      </>
    );
  } else if (values.dataSourceType.value === SOURCE_TYPE_HIVE) {
    return (
      <>
        <div className={'data-source-section-form'}>
          <label>Warehouse table name (eg hive_metastore.dw)</label>
          <div className="input-icon-row">
            <input
              type="text"
              name={FORM_FIELD.DATA_SOURCE_HIVE}
              value={values.dataSourceHive}
              onChange={(enteredText: any) =>
                handleChange(FORM_FIELD.DATA_SOURCE_HIVE, enteredText)
              }
              onBlur={handleBlur}
              placeholder={'Enter warehouse table name'}
              className={'input-states'}
            />
            {isLoading ? (
              <div className="loading-icon" />
            ) : !errors.dataSourceHive && touched.dataSourceHive ? (
              <div className="valid-icon margin-left">
                <SvgValid />
              </div>
            ) : (
              <div />
            )}
          </div>
          {errors.dataSourceHive && touched.dataSourceHive && !isLoading && (
            <div className={'form-validation-error'}>{errors.dataSourceHive}</div>
          )}
        </div>
        <HiveFilterItems
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      </>
    );
  } else {
    return <div />;
  }
}

type HiveFilterItemsProps = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: (name: string, value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
};

function HiveFilterItems({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: HiveFilterItemsProps) {
  return (
    <div className={'data-source-section-form'}>
      <label>Filters</label>
      <FieldArray name={FORM_FIELD.DATA_SOURCE_HIVE_CONDITIONS}>
        {({ insert, remove, push }) => (
          <>
            {values.dataSourceHiveConditions.length > 0 &&
              values.dataSourceHiveConditions.map(
                ({ conditionKey, conditionValue }: HiveCondition, index: number) => (
                  <React.Fragment key={index}>
                    <div className="hive-filter-container">
                      <div>
                        <Field
                          type="text"
                          name={`${FORM_FIELD.DATA_SOURCE_HIVE_CONDITIONS}.${index}.conditionKey`}
                          placeholder={'Enter filter field'}
                          className={'input-states input-field'}
                        />
                        <Field
                          type="text"
                          name={`${FORM_FIELD.DATA_SOURCE_HIVE_CONDITIONS}.${index}.conditionValue`}
                          placeholder={'Enter filter value'}
                          className={'input-states input-field margin-left'}
                        />
                      </div>
                      <div className="filter-remove-button margin-left">
                        <SvgFilterRemove onClick={() => remove(index)} />
                      </div>
                    </div>
                    <ErrorMessage
                      name={`${FORM_FIELD.DATA_SOURCE_HIVE_CONDITIONS}.${index}.conditionKey`}
                      component="div"
                      className="form-validation-error"
                    />
                  </React.Fragment>
                )
              )}
            <button
              type="button"
              className="filter-btn"
              onClick={() => push({ conditionKey: '', conditionValue: '' })}
            >
              <SvgFilter />
              Add filter
            </button>
          </>
        )}
      </FieldArray>
    </div>
  );
}
