import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { IBackend } from '../../backend';
import React from 'react';
import './SelectDataPermissions.scss';
import '../App.scss';
import { ActionMeta, OptionsType } from 'react-select';
import { FORM_FIELD, selectDataPermissionsValues } from '../../constants/add-data-constants';
import { User } from '../../types';
import AsyncSelect from 'react-select/async';

type SelectDataPermissionType = {
  backend: IBackend;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: (name: string, value: any) => void;
  handleBlur: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  onBack: () => void;
  onNext: () => void;
};

/**
 * Overrides react-select's `formatOptionLabel()` prop
 * Useful links:
 * Doc: https://react-select.com/props#prop-types
 * StackOverflow: https://stackoverflow.com/a/57284401/6700564
 * Method to overwrite how the option items look in Select dropdowns.
 * The method takes in the option object's attributes which are used
 * in the display and another parameter `context` which is used to
 * style the option depending on whether the label is being shown
 * in the menu vs selected container. For example, if the context is 'value`
 * then it means that the option label is shows as selected option in which case
 * only the name is shown (such as "Smith, John") or just the email if the full name doesn't exist
 * But if the label is being shows in the dropdown menu, then the full name is shown along
 * with the email such as "Smith, John jsmith@healthverity.com"
 */
const formatUserDisplayOptionLabel = (
  { user_id, last_name, first_name, email }: User,
  { context }: { context: string }
) => {
  if (context === 'value') {
    if (last_name && first_name) {
      return <div>{last_name + ', ' + first_name}</div>;
    } else {
      return <div>{email}</div>;
    }
  } else {
    return (
      <div style={{ display: 'flex' }}>
        {last_name && first_name && (
          <div style={{ width: '150px', marginRight: '30px' }}>{last_name + ', ' + first_name}</div>
        )}
        <div>{email}</div>
      </div>
    );
  }
};

/**
 * Component that handles the page-3 "Select data permissions" part
 * of the add data workflow asking the user for data permissions
 */
export default function SelectDataPermission({
  backend,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onBack,
  onNext,
}: SelectDataPermissionType) {
  /**
   * Async function that takes in two parameters.
   * 1. inputValue - the search input for the user search query
   * 2. callback - used to return list of users queried from DB
   * The list of users is filtered to not include a user if
   * that user exists in `values.dataAdmins`
   */
  const loadOptions = async (inputValue: string, callback: (users: User[]) => void) => {
    if (inputValue.length > 2) {
      const results = await backend.listUsers(inputValue);
      const users = results.users.filter((user: User) => {
        const isAdmin = values.dataAdmins.find((admin: User) => admin.user_id === user.user_id);
        return !isAdmin;
      });
      callback(users);
    }
  };

  return (
    <div className={'select-data-permissions-container'}>
      <div className={'select-data-permissions-section'}>
        <div className={'select-data-permissions-form'}>
          <div className={'info-box'}>
            Please indicate the HealthVerity Marketplace users who can edit and view your data. Your
            dataset is required to have at least one administrator. If you would like to add someone
            that does not currently have access you can invite them here. Data administrators and
            team members must be your colleague or a HealthVerity team member.
          </div>
          <div className={'select-data-permission-input-section'}>
            <label>Data administrator - Who can edit or update this data?</label>
            <div className={'select-data-permission-input-dropdown'}>
              <AsyncSelect
                // @ts-ignore (this is necessary as TS doesn't understand the option props)
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.last_name + ', ' + option.first_name}
                getOptionValue={(option) => String(option.user_id)}
                name={FORM_FIELD.DATA_ADMINS}
                value={values.dataAdmins}
                className={'hv-dropdown-container'}
                classNamePrefix={'hv-dropdown'}
                isSearchable={true}
                placeholder={'Search for users'}
                onChange={(value: OptionsType<any>, actionMeta: ActionMeta<any>) => {
                  handleChange(FORM_FIELD.DATA_ADMINS, value);
                }}
                onBlur={() => handleBlur(FORM_FIELD.DATA_ADMINS, true)}
                isMulti={true}
                isClearable={false}
                formatOptionLabel={formatUserDisplayOptionLabel}
                noOptionsMessage={() => 'Search for a user (at least 3 characters required)'}
              />
            </div>
            {errors.dataAdmins && touched.dataAdmins && (
              <div className={'form-validation-error'}>{errors.dataAdmins}</div>
            )}
          </div>
          <div className={'select-data-permission-input-section'}>
            <label>Team members - Who can view this data in HealthVerity Marketplace</label>
            <div className={'select-data-permission-input-dropdown'}>
              <AsyncSelect
                // @ts-ignore (this is necessary as TS doesn't understand the option props)
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.last_name + ', ' + option.first_name}
                getOptionValue={(option) => String(option.user_id)}
                name={FORM_FIELD.DATA_TEAM_MEMBERS}
                value={values.dataTeamMembers}
                className={'hv-dropdown-container'}
                classNamePrefix={'hv-dropdown'}
                isSearchable={true}
                placeholder={'Search for users'}
                onChange={(value: OptionsType<any>, actionMeta: ActionMeta<any>) => {
                  handleChange(FORM_FIELD.DATA_TEAM_MEMBERS, value);
                }}
                onBlur={() => handleBlur(FORM_FIELD.DATA_TEAM_MEMBERS, true)}
                isMulti={true}
                isClearable={false}
                formatOptionLabel={formatUserDisplayOptionLabel}
                noOptionsMessage={() => 'Search for a user (at least 3 characters required)'}
              />
            </div>
            {errors.dataTeamMembers && touched.dataTeamMembers && (
              <div className={'form-validation-error'}>{errors.dataTeamMembers}</div>
            )}
          </div>
        </div>
      </div>
      <div className={'add-new-data-footer'}>
        <input
          type="button"
          value={'Back'}
          disabled={false}
          className={'generic-dismiss-btn'}
          onClick={() => onBack()}
        />
        <input
          type="button"
          value={'Next'}
          disabled={
            // Disable if there is an error
            !!errors.dataTeamMembers || !!errors.dataAdmins
          }
          className={'generic-confirm-btn'}
          onClick={() => onNext()}
        />
      </div>
    </div>
  );
}
