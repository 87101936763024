import GenericModal from './GenericModal';
import React, { useState } from 'react';
import './GenericModal.scss';

type NotEditableModalProps = {
  close: () => void;
};

/*
 * Modal warning for non-editable tile
 */
export default function NotEditableModal({ close }: NotEditableModalProps) {
  const body = (
    <div className="generic-modal-instructions-box">
      This dataset cannot be edited at this time.
    </div>
  );

  return (
    <div>
      <GenericModal
        customModalClassName={'generic-wide-modal'}
        headerText={'Tile not editable'}
        show={true}
        close={close}
        dismissButtonText={'Close'}
        hideSubmitButton={true}
        customFooterClassName={'generic-modal-footer-border'}
      >
        {body}
      </GenericModal>
    </div>
  );
}
