import React, { useState, useEffect } from 'react';

import './Options.scss';

import archive from '../../images/archive.svg';
import activate from '../../images/activate.svg';
import makePending from '../../images/make-pending.svg';

type OptionsProps = {
  isUpdating: boolean;
  onClick: (status: string) => void;
};

/*
 * Component that displays Options for selected dataset
 * and triggers actions passed from props
 */
export default function Options({ isUpdating, onClick }: OptionsProps) {
  return (
    <div className="options-container">
      <Option
        disabled={isUpdating}
        text={'Archive data'}
        icon={archive}
        onClick={() => onClick('archived')}
      />

      <Option
        disabled={isUpdating}
        text={'Activate data'}
        icon={activate}
        onClick={() => onClick('active')}
      />

      <Option
        disabled={isUpdating}
        text={'Set to "Coming soon"'}
        icon={makePending}
        onClick={() => onClick('pending')}
      />
    </div>
  );
}

type OptionProps = {
  text: string;
  icon: any;
  onClick: () => void;
  disabled: boolean;
};

const Option = ({ text, icon, onClick, disabled }: OptionProps) => (
  <button disabled={disabled} onClick={onClick} className="option-container">
    <div className="option-icon-container">
      <img src={icon} className="option-icon" />
    </div>

    <div className="option-text-container">
      <span className="option-text">{text}</span>
    </div>
  </button>
);
