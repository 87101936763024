import React from 'react';

import './Tabs.scss';

import { ReactComponent as SvgExit } from '../../images/exit.svg';

const SEARCH_TAB_NAME = 'SEARCH RESULTS';

type TabsProps = {
  tabs: {
    name: string;
    category?: string;
  }[];
  activeTab: number;
  activateTab: (tab: number) => void;
  searchTabIndex: number;
  activeSearch: boolean;
  clearSearch: () => void;
};

/*
 * Renders all tabs passed in as props and executes navigation.
 */
export default function Tabs({
  tabs,
  activeTab,
  activateTab,
  searchTabIndex,
  activeSearch,
  clearSearch,
}: TabsProps) {
  const renderTabs = () => {
    return tabs.map((tab, index: number) => {
      return (
        <Tab
          key={tab.name}
          isActive={index === activeTab}
          activate={() => activateTab(index)}
          title={tab.name}
        />
      );
    });
  };

  return (
    <div className="tabs-container">
      {/* Renders all default tabs */}
      {renderTabs()}

      {/* Renders search tab */}
      {activeSearch && (
        <Tab
          isActive={searchTabIndex === activeTab}
          activate={() => activateTab(searchTabIndex)}
          title={SEARCH_TAB_NAME}
          activeClass={'tab-search-active'}
          inactiveClass={'tab-search'}
        >
          <div
            className="tab-search-exit-container"
            onClick={(e) => {
              e.stopPropagation();
              clearSearch();
            }}
          >
            <svg className="tab-search-exit-svg">
              <SvgExit />
            </svg>
          </div>
        </Tab>
      )}

      <div className={'tab-border-pixel'} />
    </div>
  );
}

type TabProps = {
  isActive: boolean;
  activate: () => void;
  title: string;
  activeClass?: string;
  inactiveClass?: string;
  children?: React.ReactNode;
};

/*
 * Renders one tab and executes active status
 */
function Tab({ isActive, activate, title, activeClass, inactiveClass, children }: TabProps) {
  activeClass = activeClass ? activeClass : 'tab-active';
  inactiveClass = inactiveClass ? inactiveClass : 'tab';

  return (
    <div className={'tab-container'}>
      <div className={'tab-border-pixel'}></div>
      <button className={isActive ? activeClass : inactiveClass} onClick={activate}>
        <div className={'tab-title'}>{title}</div>

        {/* Renders any additonal content after tab title */}
        {children}
      </button>
    </div>
  );
}
