import React from 'react';
import './CenterModal.scss';

type CenterModalProps = {
  show: boolean;
  close: () => void;
  children: any;
};

/**
 * Generic component that displays
 * a rectangular modal in the center
 * of the screen
 */
export default function CenterModal({ show, close, children }: CenterModalProps) {
  return (
    <React.Fragment>
      {show && (
        <React.Fragment>
          <div className="center-modal-background" onClick={close} />
          <div className="center-modal">{children}</div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
